import { Inject, LOCALE_ID } from '@angular/core'
import { Component, Input, HostListener, ElementRef, InjectionToken, Injector } from '@angular/core'
import { Overlay, OverlayConfig, ConnectedPosition, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { LanguageOverlayComponent } from './language-overlay/language-overlay.component'
import { Language } from '../header.component'

export const LANG_DATA = new InjectionToken<[{}]>('CONTAINER_DATA')

// Define interface for overlay config
interface LanguageDialogConfig {
  hasBackdrop?: boolean
  backdropClass?: string
}

@Component({
  selector: 'svi-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent {
  @Input() languages: Language[]

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private injector: Injector,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  // Open an overlay
  @HostListener('click')
  open() {
    // Set config and create overlay from config
    const dialogConfig: LanguageDialogConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    }
    const overlayRef = this.createOverlay(dialogConfig)

    // Create ComponentPortal and attach it to overlay
    const languagePortal = new ComponentPortal(
      LanguageOverlayComponent,
      null,
      this.createInjector({ languages: this.languages })
    )
    overlayRef.attach(languagePortal)

    // Subscribe to backdropClick event. If event is emitted, destroy overlay
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose())
  }

  activeLanguageName(): string {
    return this.languages.find(l => l.code === this.localeId).lang
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap()
    injectorTokens.set(LANG_DATA, data)
    return new PortalInjector(this.injector, injectorTokens)
  }

  // Create full config from given partial config. Then, create overlay from full config. Then, return.
  private createOverlay(config: LanguageDialogConfig): OverlayRef {
    // Set where the overlay gets connected relative to the host element
    const connectedPosition: ConnectedPosition = {
      offsetX: 0,
      offsetY: 45,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      weight: 1
    }

    // Set positionStrategy to connect relatively to share button
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.el)
      .withPositions([connectedPosition])

    // Create full config
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close() // Set scrollStrategy to close on scrolling
    })

    // Create overlay from config and return
    return this.overlay.create(overlayConfig)
  }
}
