import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[sviFocus]'
})
export class FocusDirective {
  @Input('sviFocus') isFocused: boolean

  constructor(private host: ElementRef) {}

  ngOnChanges() {
    if (this.isFocused) {
      this.host.nativeElement.focus()
    }
  }
}
