/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./language-overlay.component";
import * as i4 from "../language-menu.component";
import * as i5 from "@angular/router";
var styles_LanguageOverlayComponent = [i0.styles];
var RenderType_LanguageOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageOverlayComponent, data: {} });
export { RenderType_LanguageOverlayComponent as RenderType_LanguageOverlayComponent };
function View_LanguageOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "language"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLanguage(_v.context.$implicit.code) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "separator"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLanguageSelected(_v.context.$implicit.code); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.labelCode; _ck(_v, 2, 0, currVal_1); }); }
export function View_LanguageOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "languages svi-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "tooltip__arrow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageOverlayComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LanguageOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-language-overlay", [], null, null, null, View_LanguageOverlayComponent_0, RenderType_LanguageOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i3.LanguageOverlayComponent, [i4.LANG_DATA, i2.Location, i5.ActivatedRoute, i1.LOCALE_ID], null, null)], null, null); }
var LanguageOverlayComponentNgFactory = i1.ɵccf("svi-language-overlay", i3.LanguageOverlayComponent, View_LanguageOverlayComponent_Host_0, {}, {}, []);
export { LanguageOverlayComponentNgFactory as LanguageOverlayComponentNgFactory };
