import { DemoModuleConfiguration } from './demo.module.configuiration';
import { BehaviorSubject, timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "../core/tokens";
import * as i2 from "./demo.module.configuiration";
var DemoService = /** @class */ (function () {
    function DemoService(platformId, sessionStorage, localStorage, config) {
        this.platformId = platformId;
        this.sessionStorage = sessionStorage;
        this.localStorage = localStorage;
        this.config = config;
        this.started = new BehaviorSubject(false);
    }
    DemoService.prototype.resetApp = function () {
        if (isPlatformBrowser(this.platformId)) {
            sessionStorage.clear();
            localStorage.clear();
            this.stopCountdown();
            window.location.replace('');
        }
        // reload app
        this.stopCountdown();
    };
    DemoService.prototype.isStarted = function () {
        return this.started.getValue();
    };
    DemoService.prototype.stopCountdown = function () {
        this.started.next(false);
    };
    DemoService.prototype.startCountdown = function () {
        if (isPlatformBrowser(this.platformId)) {
            this.started.next(true);
            this.$userIsInactive = timer(this.config.maxInactivityInSeconds * 1000);
        }
    };
    DemoService.ngInjectableDef = i0.defineInjectable({ factory: function DemoService_Factory() { return new DemoService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.SessionStorage, 8), i0.inject(i1.LocalStorage, 8), i0.inject(i2.DEMO_MODULE_CONFIG)); }, token: DemoService, providedIn: "root" });
    return DemoService;
}());
export { DemoService };
