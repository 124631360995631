import { isPlatformBrowser } from '@angular/common'
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  ViewContainerRef
} from '@angular/core'
import { Router, RouterEvent } from '@angular/router'
import { Subscription } from 'rxjs'
import { DemoModuleConfiguration, DEMO_MODULE_CONFIG } from './demo.module.configuiration'
import { DemoService } from './demo.service'
import { EndSessionComponent } from './end-session/end-session.component'
import { ResetDemoLayoverComponent } from './reset-demo-layover/reset-demo-layover.component'

@Directive({
  selector: '[sviDemo]'
})
export class DemoDirective implements OnDestroy {
  private resetCompFactory = this.resolver.resolveComponentFactory(ResetDemoLayoverComponent)
  private endSessionCompFactory = this.resolver.resolveComponentFactory(EndSessionComponent)
  private endSessionComp: ComponentRef<EndSessionComponent>
  private resetSub: Subscription
  private closeSub: Subscription
  private endSessionSub: Subscription
  private routerSub: Subscription
  private userIsInactiveSub: Subscription

  constructor(
    private demoService: DemoService,
    @Inject(DEMO_MODULE_CONFIG) private config: DemoModuleConfiguration,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    public viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) {}

  @Input()
  set sviDemo(inactiveUrls: string[]) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.config.enabled) {
        this.routerSub = this.router.events.subscribe((val: RouterEvent) => {
          if (
            val.url &&
            inactiveUrls &&
            inactiveUrls.indexOf(val.url) === -1 &&
            !this.demoService.isStarted()
          ) {
            this.start()
            this.appendButton()
          }
        })
      }
    }
  }

  start() {
    if (this.config.enabled && isPlatformBrowser(this.platformId)) {
      if (this.userIsInactiveSub) {
        this.userIsInactiveSub.unsubscribe()
      }
      this.demoService.startCountdown()
      this.userIsInactiveSub = this.demoService.$userIsInactive.subscribe((count: number) => {
        if (count === 0) {
          this.showLayover()
        }
      })
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.routerSub) {
        this.routerSub.unsubscribe()
      }
      if (this.endSessionSub) {
        this.endSessionSub.unsubscribe()
      }
      if (this.resetSub) {
        this.resetSub.unsubscribe()
      }
      if (this.closeSub) {
        this.closeSub.unsubscribe()
      }
      if (this.userIsInactiveSub) {
        this.userIsInactiveSub.unsubscribe()
      }
    }
  }

  private appendButton() {
    this.endSessionComp = this.viewContainerRef.createComponent(this.endSessionCompFactory)
    this.endSessionSub = this.endSessionComp.instance.endSession.subscribe(() => {
      this.demoService.stopCountdown()
      if (this.endSessionSub) {
        this.endSessionSub.unsubscribe()
      }
      this.removeButton()
      this.showLayover()
    })
  }

  private removeButton() {
    this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.endSessionComp.hostView))
  }

  private showLayover() {
    const resetComp: ComponentRef<ResetDemoLayoverComponent> =
      this.viewContainerRef.createComponent(this.resetCompFactory)
    this.resetSub = resetComp.instance.reset.subscribe(() => {
      if (this.resetSub) {
        this.resetSub.unsubscribe()
      }
      this.demoService.resetApp()
    })
    this.closeSub = resetComp.instance.close.subscribe(() => {
      if (this.closeSub) {
        this.closeSub.unsubscribe()
      }
      this.viewContainerRef.clear()
      this.start()
      this.appendButton()
    })
  }

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  onActivity() {
    if (this.config.enabled && isPlatformBrowser(this.platformId)) {
      this.start()
    }
  }
}
