import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { LogoComponent } from './logo/logo.component'
import { PageTitleComponent } from './page-title/page-title.component'
import { PageContentComponent } from './page-content/page-content.component'
import { NavbarComponent } from './navbar/navbar.component'

import { ProfilePhotoComponent } from './profile-photo/profile-photo.component'
import { SwitchButtonComponent } from './switch-button/switch-button.component'
import { SocialLinksComponent } from './social-links/social-links.component'
import { TabComponent } from './tab/tab.component'
import { LoadingComponent } from './loading/loading.component'
import { TabGroupComponent } from './tab-group/tab-group.component'
import { ChartLegendItemComponent } from './chart-legend-item/chart-legend-item.component'
import { AccordionItemComponent } from './accordion-item/accordion-item.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SharingTriggerDirective } from './sharing/sharing-trigger.directive'
import { SharingOverlayComponent } from './sharing/sharing-overlay/sharing-overlay.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { NavbarMenuComponent } from './navbar/navbar-menu/navbar-menu.component'
import { NavbarMenuOverlayComponent } from './navbar/navbar-menu/navbar-menu-overlay/navbar-menu-overlay.component'
import { SharingElementDirective } from './sharing/sharing-element.directive'
import { SharingInfoDirective } from './sharing/sharing-info.directive'
import { ListItemComponent } from './list-item/list-item.component'

import { MatchingValueBarComponent } from './matching-value-bar/matching-value-bar.component'
import { CustomCheckboxComponent } from 'app/matching/custom-checkbox/custom-checkbox.component'
import { FocusDirective } from './focus/focus.directive'

const COMPONENTS = [
  LogoComponent,
  PageTitleComponent,
  PageContentComponent,
  NavbarComponent,
  ProfilePhotoComponent,
  SwitchButtonComponent,
  SocialLinksComponent,
  TabComponent,
  TabGroupComponent,
  ChartLegendItemComponent,
  AccordionItemComponent,
  SharingTriggerDirective,
  SharingOverlayComponent,
  NavbarMenuComponent,
  NavbarMenuOverlayComponent,
  SharingElementDirective,
  SharingInfoDirective,
  ListItemComponent,
  MatchingValueBarComponent,
  LoadingComponent,
  CustomCheckboxComponent,
  FocusDirective
]

@NgModule({
  imports: [CommonModule, RouterModule, BrowserAnimationsModule, OverlayModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  entryComponents: [SharingOverlayComponent, NavbarMenuOverlayComponent]
})
export class SharedModule {}
