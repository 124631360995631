import { isPlatformBrowser } from '@angular/common'
import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Inject,
  OnDestroy,
  PLATFORM_ID
} from '@angular/core'
import { Observable, Subscription, timer } from 'rxjs'
import { DemoModuleConfiguration, DEMO_MODULE_CONFIG } from '../demo.module.configuiration'

export interface Language {
  labelCode: string
  code: string
  lang: string
}

@Component({
  selector: 'svi-reset-demo-layover',
  templateUrl: './reset-demo-layover.component.html',
  styleUrls: ['./reset-demo-layover.component.scss']
})
export class ResetDemoLayoverComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter()
  @Output() reset = new EventEmitter()
  interval: Observable<number>
  intervalSub: Subscription
  duration: number
  constructor(
    @Inject(DEMO_MODULE_CONFIG) private config: DemoModuleConfiguration,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.duration = this.config.layoverClosesAfterSeconds
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.startTimer()
    }
  }

  ngOnDestroy() {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe()
    }
  }

  cancel() {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe()
    }
    this.close.emit()
  }

  resetNow() {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe()
    }
    this.reset.emit()
  }

  startTimer() {
    this.interval = timer(0, 1000)
    this.intervalSub = this.interval.subscribe((data) => {
      if (data === this.duration) {
        this.resetNow()
      }
    })
  }
}
