import { Component } from '@angular/core'
import { CmsService, Document } from '@core/cms.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'svi-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  partner: Observable<Document>

  constructor(cms: CmsService) {
    this.partner = cms
      .documents('partner')
      .pipe(map((docs) => Object.keys(docs).map((key) => docs[key])[0]))
  }
}
