import { Component, ViewChild, LOCALE_ID, Inject } from '@angular/core'
import { Location } from '@angular/common'
import { HttpParams } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import { SharingInfoDirective } from '../../shared/sharing/sharing-info.directive'
import { SharingService } from '../../shared/sharing/sharing.service'

export interface Language {
  labelCode: string
  code: string
  lang: string
}
@Component({
  selector: 'svi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('sharingInfo')
  sharingInfo: SharingInfoDirective

  // CONFIG: Languages
  languages: Language[] = [
    { labelCode: 'de', code: 'de', lang: 'deutsch' },
    { labelCode: 'fr', code: 'fr', lang: 'français' },
    { labelCode: 'it', code: 'it', lang: 'italiano' },
  ]

  constructor(
    public sharingService: SharingService,
    private location: Location,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  setLanguage(language: string) {
    let path = this.location.path(true)
    const idx = path.indexOf('?')
    // remove query part
    if (idx >= 0) {
      path = path.substr(0, idx)
    }
    const params = { ...this.route.snapshot.queryParams, locale: `${language}_CH` }
    const newUrl = `/${language}${path}?${new HttpParams({ fromObject: params }).toString()}`
    window.location.replace(newUrl)
  }

  isLanguageSelected(language: string) {
    return language === this.localeId
  }
}
