<svi-navbar></svi-navbar>
<svi-page-title #title i18n="@@profiles.title"
  >Search for the profiles of candidates & parties</svi-page-title
>
<ng-container *ngIf="(elections | async); else loading">
  <div class="svi-database-page-content">
    <svi-tab-group
      class="tabs"
      (selectedTabChanged)="onTabChanged($event)"
      [(selectedIndex)]="tabIndex"
    >
      <svi-tab label="Candidates" i18n-label="@@profiles.candidatesTab">
        <div class="svi-database-tab-content">
          <svi-database-filter-group
            [state]="filterGroupStateChanges | async"
            [disabled]="loadingdata"
            [elections]="elections | async"
            (search)="onSearch($event)"
            (reset)="onReset()"
          ></svi-database-filter-group>
          <ng-container *ngIf="showList; else placeholder">
            <ng-container *ngIf="(candidates | async)?.length > 0; else noMatch">
              <svi-list-item
                *ngFor="let candidate of (candidates | async)"
                [photoUrl]="candidate.photoUrl"
                [title]="candidate.firstname + ' ' + candidate.lastname"
                [description]="candidate.description"
                [isDirectory]="true"
                [isCandidate]="true"
                [hasProfile]="candidate.answersConfirmed"
                (click)="navigateToCandidate(candidate.id, candidate.district.electionId)"
              ></svi-list-item>
            </ng-container>
          </ng-container>
        </div>
      </svi-tab>
    </svi-tab-group>
  </div>
</ng-container>

<ng-template #loading>
  <svi-page-content>
    <svi-loading></svi-loading>
  </svi-page-content>
</ng-template>

<ng-template #placeholder>
  <svi-list-item
    *ngFor="let item of [1,2,3,4]; let i = index"
    [isPlaceholder]="true"
    [class.inactive]="true"
  >
  </svi-list-item>
</ng-template>

<ng-template #noMatch>
  <div class="no-match" i18n="@@noMatchesFound">No matches found</div>
</ng-template>

<ng-template #translations>
  <span id="elected" i18n="@@candidate.elected">Elected</span>
  <span id="incumbent" i18n="@@candidate.incumbent">Incumbent</span>
</ng-template>
