import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild
} from '@angular/core'
import { DistrictGroup, District, Party, Election } from '@smartvote/common'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TrackingService } from '../../core/tracking.service'

export class FilterGroupState {
  election = ''
  districtGroup = ''
  district = ''
  name = ''
  party = ''
  incumbent = ''
  elected = ''
  gender = ''
  answersConfirmed = ''
}

@Component({
  selector: 'svi-database-filter-group',
  templateUrl: './database-filter-group.component.html',
  styleUrls: ['./database-filter-group.component.scss']
})
export class DatabaseFilterGroupComponent implements OnDestroy, OnChanges {
  @Input() electionFilterOnly = false
  @ViewChild('selectElection') private selectElection: ElementRef
  @ViewChild('selectDistrict') private selectDistrict: ElementRef
  hasStateDefaultValue = true
  @Input()
  set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.form.disable({ emitEvent: false })
    } else {
      this.form.enable({ emitEvent: false })
    }
  }
  get disabled() {
    if (this._state.election === '' && this.elections && this.elections.length > 1) {
      return true
    }
    return this._disabled
  }
  private _disabled = false

  @Input()
  set state(value: FilterGroupState) {
    this._setState(value)
  }
  get state() {
    return this._state
  }
  @Output() search = new EventEmitter<FilterGroupState>()
  @Output() reset = new EventEmitter()

  /* Input `elections` */
  @Input()
  set elections(value: Election[]) {
    if (value) {
      this._elections = value
    } else {
      this._elections = []
    }
  }
  get elections(): Election[] {
    return this._elections
  }

  get districtGroups(): DistrictGroup[] {
    if (!this.election) {
      return []
    }
    return this.election.districtGroups
  }
  get districts(): District[] {
    if (!this.election) {
      return []
    }
    if (this.election.districtGroups.length === 0 || this.election.districtGroups.length === 1) {
      return this.election.districts
    }
    return this.election.districts.filter((d: District) => {
      return d.groupId === this.state.districtGroup
    })
  }
  get parties(): Party[] {
    if (!this.election) {
      return []
    }
    return this.election.parties
  }
  get election(): Election | undefined {
    if (this.elections && this.elections.length === 1) {
      return this.elections[0]
    }
    if (!this._state.election) {
      return
    }
    return this.elections.find((e) => e.id === this.state.election)
  }

  private _sub: Subscription
  private _elections: Election[]

  form: FormGroup
  private _state: FilterGroupState

  constructor(fb: FormBuilder, private trackingService: TrackingService) {
    this._state = new FilterGroupState()
    this.form = fb.group(this._state)
    this._sub = this.form.valueChanges.subscribe((state) => {
      this._state = { ...this.state, ...state }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['state'] &&
      JSON.stringify(changes['state'].currentValue) !== JSON.stringify(new FilterGroupState())
    ) {
      this.hasStateDefaultValue = false
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe()
  }

  onSearch() {
    this.trackingService.trackEvent('Profile', 'Search', JSON.stringify(this.form.value))
    this.form.markAsDirty()
    const districtIds = new Set(this.districts.map((d) => d.id))
    if (!districtIds.has(this.state.district)) {
      this.state.district = ''
    }
    this.search.emit(this.state)
  }

  onReset() {
    this._state = new FilterGroupState()
    this.reset.emit()
    this.hasStateDefaultValue = true
    this.form.reset(this.state)
  }

  resetDistrictGroup() {
    const state = this._state
    state.districtGroup = ''
    this.resetDistrict()
  }

  resetDistrict() {
    const state = this._state
    if (this.districts.length === 1) {
      state.district = this.districts[0].id
    } else {
      state.district = ''
    }
    this._setState(state)
  }

  setFormControlValue(formControlName: string, newValue: string) {
    const control = this.form.controls[formControlName]
    if (control) {
      control.setValue(newValue)
    }
  }

  private _setState(state: FilterGroupState = new FilterGroupState()) {
    this._state = state
    this.form.patchValue(this._state, { emitEvent: false })
  }

}
