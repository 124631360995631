import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../shared/shared.module'
import { ContactPage } from './contact/contact.page'
import { WikiPage } from './wiki/wiki.page'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

const COMPONENTS = [ContactPage, WikiPage]

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class StaticPagesModule {}
