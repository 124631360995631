/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-links.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./social-links.component";
import * as i4 from "@angular/platform-browser";
var styles_SocialLinksComponent = [i0.styles];
var RenderType_SocialLinksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialLinksComponent, data: {} });
export { RenderType_SocialLinksComponent as RenderType_SocialLinksComponent };
function View_SocialLinksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["rel", "external"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-globe"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTrustedUrl(_v.context.ngIf); _ck(_v, 0, 0, currVal_0); }); }
function View_SocialLinksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["rel", "external"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fab fa-twitter"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTrustedUrl(_v.context.ngIf); _ck(_v, 0, 0, currVal_0); }); }
function View_SocialLinksComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["rel", "external"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fab fa-facebook-square"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTrustedUrl(_v.context.ngIf); _ck(_v, 0, 0, currVal_0); }); }
function View_SocialLinksComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["rel", "external"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fab fa-youtube"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTrustedUrl(_v.context.ngIf); _ck(_v, 0, 0, currVal_0); }); }
function View_SocialLinksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "social-links-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Links:"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "social-links"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialLinksComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialLinksComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialLinksComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialLinksComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.websiteLink; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.twitterLink; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.facebookLink; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.youtubeLink; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_SocialLinksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialLinksComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.facebookLink || _co.websiteLink) || _co.twitterLink) || _co.youtubeLink); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SocialLinksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-social-links", [], null, null, null, View_SocialLinksComponent_0, RenderType_SocialLinksComponent)), i1.ɵdid(1, 49152, null, 0, i3.SocialLinksComponent, [i4.DomSanitizer], null, null)], null, null); }
var SocialLinksComponentNgFactory = i1.ɵccf("svi-social-links", i3.SocialLinksComponent, View_SocialLinksComponent_Host_0, { websiteLink: "websiteLink", twitterLink: "twitterLink", facebookLink: "facebookLink", youtubeLink: "youtubeLink" }, {}, []);
export { SocialLinksComponentNgFactory as SocialLinksComponentNgFactory };
