<svi-navbar backLabel="Back to questionnaire" i18n-backLabel="@@backToQuestionnaire"></svi-navbar>
<svi-page-title i18n="@@userSurvey.title">Questions About You</svi-page-title>
<svi-page-content>
  <div class="grid grid-reverse grid-align-top">
    <div class="cell text-right">
      <button
        class="button button-primary button-large icon-right"
        (click)="continue({saveForm: false})"
      >
        <ng-container i18n="@@userSurvey.skipUserSurvey"
          >Continue directly to your results</ng-container
        >
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="cell">
      <p class="hint">
        <b i18n="@@userSurvey.dataNote">Please note</b>:
        <ng-container i18n="@@userSurvey.dataHandling"
          >your answers will be treated anonymously and will not affect your results</ng-container
        >
      </p>
    </div>
  </div>

  <form class="svi-questions-about-you-form" [formGroup]="form">
    <div class="section">
      <h3 i18n="@@userSurvey.biographics">Biographical information</h3>
      <div class="grid grid-gutter">
        <div class="field cell no-hint no-title">
          <div class="field-inner">
            <i class="fa fa-home icon-group"></i>
            <input
              class="form-control"
              type="text"
              placeholder="Country"
              i18n-placeholder="@@userSurvey.field_1.placeholder"
              formControlName="field_1"
            />
          </div>
        </div>
        <div class="field cell no-title">
          <div class="field-inner">
            <i class="fa fa-birthday-cake icon-group"></i>
            <input
              class="form-control"
              type="number"
              placeholder="Year of birth"
              i18n-placeholder="@@userSurvey.field_2.placeholder"
              formControlName="field_2"
              [ngClass]="{'invalid-year' : form.controls.field_2.invalid && form.controls.field_2.touched }"
            />
          </div>
          <div class="field-hint">
            <div
              *ngIf="form.controls.field_2.valid || !form.controls.field_2.touched"
              i18n="@@userSurvey.field_2.example"
            >
              Example: 1968
            </div>
            <div
              class="error"
              *ngIf="form.controls.field_2.invalid && form.controls.field_2.touched"
              i18n="@@userSurvey.field_2.error"
            >
              Please enter four-digit year of birth.
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell no-hint">
          <div class="field-title" i18n="@@userSurvey.drop_1.label">Gender</div>
          <div class="field-inner">
            <div class="icon-group">
              <i class="fa fa-male"></i>
              <i class="fa fa-female"></i>
            </div>
            <select class="form-control" formControlName="drop_1">
              <option value="">-</option>
              <option value="1" i18n="@@userSurvey.drop_1.value_1">Female</option>
              <option value="2" i18n="@@userSurvey.drop_1.value_2">Male</option>
              <option value="3" i18n="@@userSurvey.drop_1.value_3">Other</option>
              <option value="4" i18n="@@userSurvey.drop_1.value_4">NA</option>
            </select>
          </div>
        </div>
        <div class="field cell no-hint">
          <div class="field-title" i18n="@@userSurvey.drop_2.label">Language spoken</div>
          <div class="field-inner">
            <i class="fa fa-language icon-group"></i>
            <select class="form-control" formControlName="drop_2">
              <option value="">-</option>
              <option value="1" i18n="@@userSurvey.drop_2.value_1">Answer1</option>
              <option value="2" i18n="@@userSurvey.drop_2.value_2">Answer2</option>
              <option value="3" i18n="@@userSurvey.drop_2.value_3">Answer3</option>
              <option value="4" i18n="@@userSurvey.drop_2.value_4">Answer4</option>
              <option value="5" i18n="@@userSurvey.drop_2.value_5">Answer5</option>
              <option value="6" i18n="@@userSurvey.drop_2.value_6">Answer6</option>
              <option value="7" i18n="@@userSurvey.drop_2.value_7">Answer7</option>
              <option value="8" i18n="@@userSurvey.drop_2.value_8">Answer8</option>
              <option value="9" i18n="@@userSurvey.drop_2.value_9">Answer9</option>
              <option value="10" i18n="@@userSurvey.drop_2.value_10">Answer10</option>
              <option value="11" i18n="@@userSurvey.drop_2.value_11">Answer11</option>
            </select>
          </div>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell no-hint">
          <div class="field-title" i18n="@@userSurvey.drop_3.label">Education</div>
          <div class="field-inner">
            <i class="fa fa-graduation-cap icon-group"></i>
            <select class="form-control" formControlName="drop_3">
              <option value="">-</option>
              <option value="1" i18n="@@userSurvey.drop_3.value_1">Answer1</option>
              <option value="2" i18n="@@userSurvey.drop_3.value_2">Answer2</option>
              <option value="3" i18n="@@userSurvey.drop_3.value_3">Answer3</option>
              <option value="4" i18n="@@userSurvey.drop_3.value_4">Answer4</option>
              <option value="5" i18n="@@userSurvey.drop_3.value_5">Answer5</option>
              <option value="6" i18n="@@userSurvey.drop_3.value_6">Answer6</option>
              <option value="7" i18n="@@userSurvey.drop_3.value_7">Answer7</option>
              <option value="8" i18n="@@userSurvey.drop_3.value_8">Answer8</option>
              <option value="9" i18n="@@userSurvey.drop_3.value_9">Answer9</option>
            </select>
          </div>
        </div>
        <div class="field cell no-hint">
          <div class="field-title" i18n="@@userSurvey.drop_4.label">Employment</div>
          <div class="field-inner">
            <i class="fa fa-briefcase icon-group"></i>
            <select class="form-control" formControlName="drop_4">
              <option value="">-</option>
              <option value="1" i18n="@@userSurvey.drop_4.value_1">Answer1</option>
              <option value="2" i18n="@@userSurvey.drop_4.value_2">Answer2</option>
              <option value="3" i18n="@@userSurvey.drop_4.value_3">Answer3</option>
              <option value="4" i18n="@@userSurvey.drop_4.value_4">Answer4</option>
              <option value="5" i18n="@@userSurvey.drop_4.value_5">Answer5</option>
              <option value="6" i18n="@@userSurvey.drop_4.value_6">Answer6</option>
              <option value="7" i18n="@@userSurvey.drop_4.value_7">Answer7</option>
              <option value="8" i18n="@@userSurvey.drop_4.value_8">Answer8</option>
              <option value="9" i18n="@@userSurvey.drop_4.value_9">Answer9</option>
              <option value="10" i18n="@@userSurvey.drop_4.value_10">Answer10</option>
              <option value="11" i18n="@@userSurvey.drop_4.value_11">Answer11</option>
            </select>
          </div>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell no-hint">
          <div class="field-title" i18n="@@userSurvey.drop_5.label">Household Income</div>
          <div class="field-inner">
            <i class="fa fa-dollar-sign icon-group"></i>
            <select class="form-control" formControlName="drop_5">
              <option value="">-</option>
              <option value="1" i18n="@@userSurvey.drop_5.value_1">Answer1</option>
              <option value="2" i18n="@@userSurvey.drop_5.value_2">Answer2</option>
              <option value="3" i18n="@@userSurvey.drop_5.value_3">Answer3</option>
              <option value="4" i18n="@@userSurvey.drop_5.value_4">Answer4</option>
              <option value="5" i18n="@@userSurvey.drop_5.value_5">Answer5</option>
              <option value="6" i18n="@@userSurvey.drop_5.value_6">Answer6</option>
              <option value="7" i18n="@@userSurvey.drop_5.value_7">Answer7</option>
              <option value="8" i18n="@@userSurvey.drop_5.value_8">Answer8</option>
            </select>
          </div>
        </div>
        <div class="field cell no-hint">
          <div class="field-title" i18n="@@userSurvey.drop_6.label">Interest in politics</div>
          <div class="field-inner">
            <i class="fa fa-crown icon-group"></i>
            <select class="form-control" formControlName="drop_6">
              <option value="">-</option>
              <option value="1" i18n="@@userSurvey.drop_6.value_1">Answer1</option>
              <option value="2" i18n="@@userSurvey.drop_6.value_2">Answer2</option>
              <option value="3" i18n="@@userSurvey.drop_6.value_3">Answer3</option>
              <option value="4" i18n="@@userSurvey.drop_6.value_4">Answer4</option>
              <option value="5" i18n="@@userSurvey.drop_6.value_5">Answer5</option>
              <option value="6" i18n="@@userSurvey.drop_6.value_6">Answer6</option>
              <option value="7" i18n="@@userSurvey.drop_6.value_7">Answer7</option>
            </select>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="hint">
          <ng-container i18n="@@userSurvey.votingPreferenceQuestion_1"
            >How do you plan to vote in the upcoming election?</ng-container
          >
        </p>
        <div class="field cell">
          <select class="form-control" formControlName="drop_7">
            <option value="">-</option>
            <option value="1" i18n="@@userSurvey.drop_7.value_1">Answer1</option>
            <option value="2" i18n="@@userSurvey.drop_7.value_2">Answer2</option>
            <option value="3" i18n="@@userSurvey.drop_7.value_3">Answer3</option>
            <option value="4" i18n="@@userSurvey.drop_7.value_4">Answer4</option>
            <option value="5" i18n="@@userSurvey.drop_7.value_5">Answer5</option>
            <option value="6" i18n="@@userSurvey.drop_7.value_6">Answer6</option>
            <option value="7" i18n="@@userSurvey.drop_7.value_7">Answer7</option>
            <option value="8" i18n="@@userSurvey.drop_7.value_8">Answer8</option>
          </select>
        </div>
      </div>
      <div class="section">
        <p class="hint">
          <ng-container i18n="@@userSurvey.votingPreferenceExplanation"
            >Where would you place yourself on a scale from 'left' (1) to 'right' (5)</ng-container
          >
        </p>
        <div class="party-pref field field-left cell">
          <div class="answer-container">
            <div class="answer-options">
              <button
                (click)="setFormValue('scale_1', 1)"
                [ngClass]="{ active: getFormValue('scale_1') === 1 }"
              >
                1
              </button>
              <button
                (click)="setFormValue('scale_1', 2)"
                [ngClass]="{ active: getFormValue('scale_1') === 2 }"
              >
                2
              </button>
              <button
                (click)="setFormValue('scale_1', 3)"
                [ngClass]="{ active: getFormValue('scale_1') === 3 }"
              >
                3
              </button>
              <button
                (click)="setFormValue('scale_1', 4)"
                [ngClass]="{ active: getFormValue('scale_1') === 4 }"
              >
                4
              </button>
              <button
                (click)="setFormValue('scale_1', 5)"
                [ngClass]="{ active: getFormValue('scale_1') === 5 }"
              >
                5
              </button>
              <button
                (click)="setFormValue('scale_1', 6)"
                [ngClass]="{ active: getFormValue('scale_1') === 6 }"
              >
                6
              </button>
              <button
                (click)="setFormValue('scale_1', 7)"
                [ngClass]="{ active: getFormValue('scale_1') === 7 }"
              >
                7
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h3 i18n="@@userSurvey.partyPreference">Party preference</h3>
      <div class="section">
        <p class="hint">
          <ng-container i18n="@@userSurvey.votingPreferenceQuestion_2"
            >Which party did you vote for in the last election?</ng-container
          >
        </p>
        <div class="field cell">
          <select class="form-control" formControlName="drop_8">
            <option value="">-</option>
            <option value="1" i18n="@@userSurvey.drop_8.value_1">Answer1</option>
            <option value="2" i18n="@@userSurvey.drop_8.value_2">Answer2</option>
            <option value="3" i18n="@@userSurvey.drop_8.value_3">Answer3</option>
            <option value="4" i18n="@@userSurvey.drop_8.value_4">Answer4</option>
            <option value="5" i18n="@@userSurvey.drop_8.value_5">Answer5</option>
            <option value="6" i18n="@@userSurvey.drop_8.value_6">Answer6</option>
            <option value="7" i18n="@@userSurvey.drop_8.value_7">Answer7</option>
            <option value="8" i18n="@@userSurvey.drop_8.value_8">Answer8</option>
            <option value="9" i18n="@@userSurvey.drop_8.value_9">Answer9</option>
            <option value="10" i18n="@@userSurvey.drop_8.value_10">Answer10</option>
            <option value="11" i18n="@@userSurvey.drop_8.value_11">Answer11</option>
          </select>
        </div>
      </div>
      <div *ngIf="(parties | async)?.length > 0" class="section">
        <p class="hint">
          <ng-container i18n="@@userSurvey.partyPreferenceIntroduction"
            >How likely is it that you vote for a candidate from the following
            parties?</ng-container
          >
          <br />
          <ng-container i18n="@@userSurvey.partyPreferenceExplanation"
            >'1' means "not at all likely" and '5' means "very likely”.</ng-container
          >
        </p>
        <div class="grid grid-gutter" *ngFor="let party of parties | async">
          <div class="party-pref field cell">
            <label> {{party.name}}:</label>
            <div class="answer-container">
              <div class="answer-options">
                <button
                  (click)="setPartyPreference(party.id, 1)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 1 }"
                >
                  1
                </button>
                <button
                  (click)="setPartyPreference(party.id, 2)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 2 }"
                >
                  2
                </button>
                <button
                  (click)="setPartyPreference(party.id, 3)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 3 }"
                >
                  3
                </button>
                <button
                  (click)="setPartyPreference(party.id, 4)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 4 }"
                >
                  4
                </button>
                <button
                  (click)="setPartyPreference(party.id, 5)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 5 }"
                >
                  5
                </button>
                <button
                  (click)="setPartyPreference(party.id, 6)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 6 }"
                >
                  6
                </button>
                <button
                  (click)="setPartyPreference(party.id, 7)"
                  [ngClass]="{ active: getPartyPreference(party.id) === 7 }"
                >
                  7
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-gutter">
          <div class="party-pref field cell">
            <label i18n="@@party.otherParties">Other Party:</label>
            <div class="answer-container">
              <div class="answer-options">
                <button
                  (click)="setFormValue('other_party', 1)"
                  [ngClass]="{ active: getFormValue('other_party') === 1 }"
                >
                  1
                </button>
                <button
                  (click)="setFormValue('other_party', 2)"
                  [ngClass]="{ active: getFormValue('other_party') === 2 }"
                >
                  2
                </button>
                <button
                  (click)="setFormValue('other_party', 3)"
                  [ngClass]="{ active: getFormValue('other_party') === 3 }"
                >
                  3
                </button>
                <button
                  (click)="setFormValue('other_party', 4)"
                  [ngClass]="{ active: getFormValue('other_party') === 4 }"
                >
                  4
                </button>
                <button
                  (click)="setFormValue('other_party', 5)"
                  [ngClass]="{ active: getFormValue('other_party') === 5 }"
                >
                  5
                </button>
                <button
                  (click)="setFormValue('other_party', 6)"
                  [ngClass]="{ active: getFormValue('other_party') === 6 }"
                >
                  6
                </button>
                <button
                  (click)="setFormValue('other_party', 7)"
                  [ngClass]="{ active: getFormValue('other_party') === 7 }"
                >
                  7
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-gutter" *ngIf="getFormValue('other_party')">
          <div class="cell field">
            <label for="other_party_name" i18n="@@userSurvey.otherPartiesNameLabel"
              >Please specify other party
            </label>
          </div>
          <div class="cell">
            <input
              type="text"
              class="form-control"
              id="other_party_name"
              placeholder="Other party name"
              i18n-placeholder="@@userSurvey.otherPartiesNamePlaceholder"
              formControlName="other_party_name"
            />
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="userSurveyDoc | async">
    <div class="cms" [innerHTML]="(userSurveyDoc | async).html"></div>
  </ng-container>

  <div class="grid grid-gutter grid-align-bottom section">
    <div class="cell">
      <h3 i18n="@@userSurvey.thankYou">Thank you for participating!</h3>
    </div>
    <div class="cell text-right">
      <button
        class="button button-primary button-large icon-right"
        (click)="continue({saveForm: true})"
        [disabled]="form.controls.field_2.invalid"
      >
        <ng-container i18n="@@userSurvey.showResults">See results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
  </div>
</svi-page-content>
