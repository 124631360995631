import { Component } from '@angular/core'
import { CmsService, WikiDocumentLink } from '../cms.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'svi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  year: string = new Date().getFullYear().toString()
  footerLinks: Observable<WikiDocumentLink[]>

  constructor(cms: CmsService) {
    this.footerLinks = cms.getWikiDocumentLinks('wiki_doc', ['footer'])
  }
}
