import { isPlatformBrowser } from '@angular/common';
import { ModuleWithProviders, PLATFORM_ID } from '@angular/core';
import { DEMO_MODULE_CONFIG, LocalStorage, SessionStorage } from './demo.module.configuiration';
import { ResetDemoLayoverComponent } from './reset-demo-layover/reset-demo-layover.component';
import { DemoDirective } from './demo.directive';
import { EndSessionComponent } from './end-session/end-session.component';
var sessionStorageFactory = function (platformId) {
    if (isPlatformBrowser(platformId)) {
        return window.sessionStorage;
    }
};
var ɵ0 = sessionStorageFactory;
var localStorageFactory = function (platformId) {
    if (isPlatformBrowser(platformId)) {
        return window.localStorage;
    }
};
var ɵ1 = localStorageFactory;
var COMPONENTS = [ResetDemoLayoverComponent, DemoDirective, EndSessionComponent];
var DemoModule = /** @class */ (function () {
    function DemoModule() {
    }
    DemoModule.forRoot = function (config) {
        return {
            ngModule: DemoModule,
            providers: [
                { provide: DEMO_MODULE_CONFIG, useValue: config },
                {
                    provide: LocalStorage,
                    useFactory: localStorageFactory,
                    deps: [PLATFORM_ID]
                },
                {
                    provide: SessionStorage,
                    useFactory: sessionStorageFactory,
                    deps: [PLATFORM_ID]
                }
            ]
        };
    };
    return DemoModule;
}());
export { DemoModule };
export { ɵ0, ɵ1 };
