import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { SharedModule } from '../shared/shared.module'

import { QuestionsAboutYouPage } from './questions-about-you.page'
import { QuestionsAboutYouModuleConfiguration } from './questions-about-you.module.configuration'

export const QUESTIONS_ABOUT_YOU_MODULE_CONFIG = new InjectionToken<QuestionsAboutYouModuleConfiguration>(
  'QuestionsAboutYouModuleConfiguration'
)

const COMPONENTS = [QuestionsAboutYouPage]

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class QuestionsAboutYouModule {
  public static forRoot(config: QuestionsAboutYouModuleConfiguration): ModuleWithProviders {
    return {
      ngModule: QuestionsAboutYouModule,
      providers: [{ provide: QUESTIONS_ABOUT_YOU_MODULE_CONFIG, useValue: config }]
    }
  }
}
