<div class="policy-layover" *ngIf="!accepted">
  <div class="policy-layover__inner">
    <div class="content">
      <div *ngIf="languages.length > 1" class="languages">
        <ng-container *ngFor="let language of languages">
          <a class="language" (click)="setLanguage(language.code)">{{ language.labelCode }}</a>
          <span class="separator"></span>
        </ng-container>
      </div>
      <h2 i18n="@@privacyWall.title">Privacy policy and terms of use</h2>
      <div class="text">
        <ng-container *ngIf="privacyBannerDoc | async">
          <div [innerHTML]="(privacyBannerDoc | async).html"></div>
        </ng-container>
      </div>
      <div class="actions">
        <button class="button button-accept" (click)="accept()" i18n="@@privacyWall.accept">
          I accept
        </button>
      </div>
    </div>
  </div>
</div>
