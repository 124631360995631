import { CommonModule, isPlatformBrowser } from '@angular/common'
import { ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  DemoModuleConfiguration,
  DEMO_MODULE_CONFIG,
  LocalStorage,
  SessionStorage
} from './demo.module.configuiration'
import { ResetDemoLayoverComponent } from './reset-demo-layover/reset-demo-layover.component'
import { DemoDirective } from './demo.directive'
import { EndSessionComponent } from './end-session/end-session.component'

const sessionStorageFactory = (platformId) => {
  if (isPlatformBrowser(platformId)) {
    return window.sessionStorage
  }
}

const localStorageFactory = (platformId) => {
  if (isPlatformBrowser(platformId)) {
    return window.localStorage
  }
}

const COMPONENTS = [ResetDemoLayoverComponent, DemoDirective, EndSessionComponent]

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule],
  declarations: COMPONENTS,
  entryComponents: [ResetDemoLayoverComponent, EndSessionComponent],
  exports: COMPONENTS
})
export class DemoModule {
  public static forRoot(config: DemoModuleConfiguration): ModuleWithProviders<DemoModule> {
    return {
      ngModule: DemoModule,
      providers: [
        { provide: DEMO_MODULE_CONFIG, useValue: config },
        {
          provide: LocalStorage,
          useFactory: localStorageFactory,
          deps: [PLATFORM_ID]
        },
        {
          provide: SessionStorage,
          useFactory: sessionStorageFactory,
          deps: [PLATFORM_ID]
        }
      ]
    }
  }
}
