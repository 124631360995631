<div class="svi-layout">
  <svi-header></svi-header>
  <div class="content">
    <router-outlet></router-outlet>
    <div class="sponsors">
      <div class="content partner-logo__container" [innerHTML]="(partner | async)?.html"></div>
    </div>
  </div>
</div>
<svi-footer></svi-footer>
