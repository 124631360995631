import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { getResponderType, isCandidate } from '@smartvote/common';
import { groupBy } from 'lodash';
import { getQuestionItems } from './question-item';
var DEFAULT_RESPONDER_COLOR = 'f29400';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(config, router, localeId) {
        this.config = config;
        this.router = router;
        this.localeId = localeId;
        // Tabs
        this.selectedTabChanged = new EventEmitter();
        this.tabIndex = 0;
        this.responderColor = DEFAULT_RESPONDER_COLOR;
        this.sharePath =
            '/' + this.localeId + this.router.routerState.snapshot.url.replace('matching', 'share');
    }
    Object.defineProperty(ProfileComponent.prototype, "voter", {
        get: function () {
            return this._voter;
        },
        set: function (value) {
            if (value) {
                this._voter = tslib_1.__assign({}, value, { smartspider: tslib_1.__assign({}, value.smartspider, { options: { cssClass: 'svi-smartspider-voter' } }) });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "questions", {
        set: function (value) {
            if (value) {
                this._questions = groupBy(value, function (q) { return q.category.name; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "categories", {
        get: function () {
            return Object.keys(this._questions);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "responder", {
        get: function () {
            return this._responder;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this.responderColor = this.getResponderColor(value);
            this._responder = tslib_1.__assign({}, value, { smartspider: tslib_1.__assign({}, value.smartspider, { options: {
                        fill: "#" + this.responderColor
                    } }) });
            this.displayName = isCandidate(value) ? value.firstname + " " + value.lastname : value.name;
            this.hasComment = value.answers.some(function (entry) { return !!entry.comment; });
        },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.questionItems = {};
        var prevQuestions = 0;
        this.categories.forEach(function (category) {
            var questions = _this._questions[category];
            _this.questionItems[category] = getQuestionItems(questions, _this.responder.answers, _this.voter && _this.voter.isMe ? _this.voter.answers : [], prevQuestions);
            prevQuestions += questions.length;
        });
    };
    ProfileComponent.prototype.ngOnChanges = function () {
        this.sharePath =
            '/' + this.localeId + this.router.routerState.snapshot.url.replace('matching', 'share');
    };
    ProfileComponent.prototype.getValue = function (responder, key, defaultValue) {
        var result = responder.values.find(function (entry) { return entry.key === key; });
        if (!result || !result.value) {
            return defaultValue;
        }
        return result.value;
    };
    ProfileComponent.prototype.getResponderColor = function (responder) {
        if (getResponderType(responder) === 'Candidate') {
            return this.getValue(responder.party, 'color', DEFAULT_RESPONDER_COLOR);
        }
        else if (getResponderType(responder) === 'Party') {
            return this.getValue(responder, 'color', DEFAULT_RESPONDER_COLOR);
        }
    };
    return ProfileComponent;
}());
export { ProfileComponent };
