/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wiki.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../core/tokens";
import * as i7 from "../../shared/page-title/page-title.component.ngfactory";
import * as i8 from "../../shared/page-title/page-title.component";
import * as i9 from "../../shared/page-content/page-content.component.ngfactory";
import * as i10 from "../../shared/page-content/page-content.component";
import * as i11 from "./wiki.page";
import * as i12 from "../../core/cms.service";
var styles_WikiPage = [i0.styles];
var RenderType_WikiPage = i1.ɵcrt({ encapsulation: 0, styles: styles_WikiPage, data: {} });
export { RenderType_WikiPage as RenderType_WikiPage };
export function View_WikiPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavbarComponent, [i4.Location, i5.Router, i6.LocalStorage], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "svi-page-title", [], null, null, null, i7.View_PageTitleComponent_0, i7.RenderType_PageTitleComponent)), i1.ɵdid(3, 49152, null, 0, i8.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 3, "svi-page-content", [], null, null, null, i9.View_PageContentComponent_0, i9.RenderType_PageContentComponent)), i1.ɵdid(7, 49152, null, 0, i10.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "div", [["class", "cms"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.footerLinks))) == null) ? null : tmp_0_0.title); _ck(_v, 4, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.doc))) == null) ? null : tmp_1_0.html); _ck(_v, 8, 0, currVal_1); }); }
export function View_WikiPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-wiki", [], null, null, null, View_WikiPage_0, RenderType_WikiPage)), i1.ɵdid(1, 49152, null, 0, i11.WikiPage, [i5.ActivatedRoute, i12.CmsService], null, null)], null, null); }
var WikiPageNgFactory = i1.ɵccf("svi-wiki", i11.WikiPage, View_WikiPage_Host_0, {}, {}, []);
export { WikiPageNgFactory as WikiPageNgFactory };
