import { InjectionToken } from '@angular/core'

export interface DemoModuleConfiguration {
  layoverClosesAfterSeconds: number
  maxInactivityInSeconds: number
  enabled: boolean
}

export const DEMO_MODULE_CONFIG = new InjectionToken<DemoModuleConfiguration>(
  'DemoModuleConfiguration'
)

export const LocalStorage = new InjectionToken<Storage>('localStorage')
export const SessionStorage = new InjectionToken<Storage>('sessionStorage')
