<div class="header" (click)="toggle()">
  <i class="fa fa-angle-right" [@indicatorRotate]="_getExpandedState()"></i>
  {{ title }}
</div>
<div
  class="content"
  [@bodyExpansion]="_getExpandedState()"
  (@bodyExpansion.done)="_bodyAnimation($event)"
  (@bodyExpansion.start)="_bodyAnimation($event)"
>
  <ng-content></ng-content>
</div>
