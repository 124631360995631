<div class="svi-profile">
  <svi-profile-photo class="photo" [photoUrl]="responder.photoUrl"></svi-profile-photo>
  <div class="content profile-header">
    <div class="name">{{ displayName }}</div>
    <div class="social d-none d-sm-block">
      <svi-social-links
        class="d-none d-sm-block"
        [websiteLink]="getValue(responder, 'website')"
        [twitterLink]="getValue(responder, 'twitter')"
        [facebookLink]="getValue(responder, 'facebook')"
        [youtubeLink]="getValue(responder, 'youtube')"
      ></svi-social-links>
    </div>
  </div>
  <div class="content profile-details">
    <ng-content></ng-content>
  </div>
  <div class="content social d-block d-sm-none">
    <svi-social-links
      class="d-block d-sm-none"
      [websiteLink]="getValue(responder, 'website')"
      [twitterLink]="getValue(responder, 'twitter')"
      [facebookLink]="getValue(responder, 'facebook')"
      [youtubeLink]="getValue(responder, 'youtube')"
    ></svi-social-links>
  </div>
</div>

<svi-tab-group
  *ngIf="responder.answers.length > 0"
  class="tabs"
  (selectedTabChanged)="selectedTabChanged.emit($event)"
  [(selectedIndex)]="tabIndex"
>
  <!-- Sharing info -->
  <ng-container
    #sharingInfo2="sviSharingInfo"
    sviSharingInfo
    title="smartvote international demo"
    i18n-title="@@profile.smartspiderShareTitle"
    description="Here is my smartspider compared with the smarspider of {{ displayName }}"
    i18n-description="@@profile.smartspiderShareDescriptionCandidateAndVoter"
    [imagePath]="'/assets/images/svint_demo_static_sharing_image_' + localeId + '.jpg'"
    [sharePath]="sharePath"
  ></ng-container>
  <svi-tab
    *ngIf="config.hasSmartspider"
    label="Smartspider"
    i18n-label="@@profile.smartspiderTabLabel"
  >
    <div class="button-group d-none d-sm-block">
      <label class="checkbox-container"
        ><ng-container i18n="@@profile.showMySmartspiderCheckbox">Show my smartspider</ng-container>
        <input [(ngModel)]="showMySmartspider" type="checkbox" checked="checked" />
        <span class="checkmark"></span>
      </label>
      <button
        *ngIf="voter.isMe && config.showShareButton"
        sviSharingTrigger
        [sharingInfo]="sharingInfo2"
        class="button button-share"
      >
        <i class="fa fa-share"></i>
        <ng-container i18n="@@profile.shareMySmartSpider">Share smartspider</ng-container>
      </button>
    </div>
    <sv-smartspider
      #sharingElement="sviSharingElement"
      sviSharingElement
      [screenshotHeight]="650"
      class="smartspider"
      [smartspiders]="
        voter && showMySmartspider
          ? [responder.smartspider, voter.smartspider]
          : [responder.smartspider]
      "
    ></sv-smartspider>
    <div class="smartspider-legend">
      <svi-chart-legend-item
        *ngIf="showMySmartspider"
        class="legend-item"
        label="My smartspider"
        i18n-label="@@profile.mySmartspiderLegend"
      ></svi-chart-legend-item>
      <svi-chart-legend-item
        class="legend-item"
        [color]="'#' + responderColor"
        [label]="displayName"
      ></svi-chart-legend-item>
    </div>
    <div class="button-group-mobile d-block d-sm-none">
      <label class="checkbox-container"
        ><ng-container i18n="@@profile.showSmartspiderCheckbox">Show my smartspider</ng-container>
        <input [(ngModel)]="showMySmartspider" type="checkbox" checked="checked" />
        <span class="checkmark"></span>
      </label>
      <button
        *ngIf="voter.isMe && config.showShareButton"
        sviSharingTrigger
        [sharingInfo]="sharingInfo2"
        class="button button-share"
      >
        <i class="fa fa-share"></i>
        <ng-container i18n="@@profile.shareMySmartSpider">Share smartspider</ng-container>
      </button>
    </div>
    <div class="more-info">
      <svi-smartspider-methodology></svi-smartspider-methodology>
    </div>
  </svi-tab>
  <svi-tab label="Questionnaire" i18n-label="@@profile.questionnaireTab">
    <div class="answer-option-selection-header">
      <span class="header-item" *ngIf="hasComment">
        <i class="fa fa-comment"></i>&nbsp;
        <ng-container i18n="@@profile.comment">Comment</ng-container>
      </span>
      <span class="header-item" *ngIf="voter && voter.isMe">
        <i class="fa fa-user-circle"></i>&nbsp;
        <ng-container i18n="@@profile.me">Me</ng-container>
      </span>
      <svi-chart-legend-item
        class="header-item"
        [color]="'#' + responderColor"
        [label]="displayName"
      >
      </svi-chart-legend-item>
    </div>
    <cdk-accordion multi="true">
      <svi-accordion-item
        *ngFor="let category of categories; first as isFirstCategory"
        [title]="category"
        [expanded]="isFirstCategory"
      >
        <svi-question-item
          class="svi-question-item"
          [class.active]="i % 2 === 0"
          *ngFor="let qItem of questionItems[category]; first as isFirst; let i = index"
          [text]="qItem.text"
          [comment]="qItem.comment"
          [index]="qItem.index"
        >
          <svi-answer-option-group
            *ngIf="qItem.type !== 'Standard5Question'"
            class="answer-option-group"
          >
            <svi-answer-option-item
              [selections]="qItem.answerSelections['100']"
              label="Yes"
              i18n-label="@@profile.answerYes"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['75']"
              label="Rather yes"
              i18n-label="@@profile.answerRatherYes"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['25']"
              label="Rather no"
              i18n-label="@@profile.answerRatherNo"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['0']"
              label="No"
              i18n-label="@@profile.answerNo"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
          </svi-answer-option-group>
          <svi-answer-option-group
            *ngIf="qItem.type === 'Standard5Question'"
            class="answer-option-group"
          >
            <svi-answer-option-item
              [selections]="qItem.answerSelections['0']"
              label="Much less"
              i18n-label="@@profile.answerMuchLess"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['25']"
              label="Less"
              i18n-label="@@profile.answerLess"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['50']"
              label="Same"
              i18n-label="@@profile.answerNeutral"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['75']"
              label="More"
              i18n-label="@@profile.answerMore"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['100']"
              label="Much more"
              i18n-label="@@profile.answerMuchMore"
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
          </svi-answer-option-group>
        </svi-question-item>
      </svi-accordion-item>
    </cdk-accordion>
  </svi-tab>
</svi-tab-group>
