<div class="d-none d-sm-block">
  <div class="svi-navbar">
    <a *ngIf="backLabel" class="nav-item nav-back" (click)="onBack()">
      <i class="fa fa-angle-double-left"></i> {{ backLabel }}
    </a>
    <ng-container *ngIf="!hideMenu">
      <span class="spacer"></span>
      <a class="nav-item" [routerLink]="'/home'" routerLinkActive="active" i18n="@@menu.home.title"
        >Home</a
      >
      <a
        class="nav-item"
        [routerLink]="'/matching'"
        (click)="goToMatching()"
        routerLinkActive="active"
        i18n="@@menu.matching.title"
        >Matching</a
      >
      <a
        class="nav-item"
        [routerLink]="'/profiles'"
        routerLinkActive="active"
        i18n="@@menu.profiles.title"
        >Profiles</a
      >
    </ng-container>
  </div>
</div>
<div class="d-block d-sm-none">
  <svi-navbar-menu></svi-navbar-menu>
</div>
