<footer class="svi-footer container">
  <div class="footer-links">
    <a
      *ngFor="let footerLink of footerLinks | async"
      [routerLink]="footerLink.link"
      class="footer-link"
      >{{ footerLink.title }}</a
    >
    <a routerLink="/contact" class="footer-link" i18n="@@contact.title">Contact</a>
  </div>
  <svi-logo size="small"></svi-logo>
  <div class="copyright">
    ©&nbsp;
    <ng-container i18n="@@footer.copyright">Politools</ng-container>
    &nbsp;{{ year }}
  </div>
</footer>
