import { Component, Inject, AfterViewInit, PLATFORM_ID } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Apollo } from 'apollo-angular'
import { isPlatformBrowser } from '@angular/common'

import { GetElectionPartiesQuery } from '../../__generated__/types'
import { map, tap } from 'rxjs/operators'
import { Party } from '@smartvote/common'
import { Observable } from 'rxjs'
import { LocalStorage } from '../core/tokens'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { TrackingService } from '../core/tracking.service'
import { CmsService, Document } from '../core/cms.service'
import { environment } from 'environments/environment'
import { QUESTIONS_ABOUT_YOU_MODULE_CONFIG } from './questions-about-you.module'
import { QuestionsAboutYouModuleConfiguration } from './questions-about-you.module.configuration'

const { GetElectionParties } = require('graphql-tag/loader!./questions-about-you.page.graphql')

export const USER_SURVEY_KEY = 'user-survey'

@Component({
  selector: 'svi-questions-about-you-page',
  templateUrl: 'questions-about-you.page.html',
  styleUrls: ['questions-about-you.page.scss']
})
export class QuestionsAboutYouPage implements AfterViewInit {
  form: FormGroup
  parties: Observable<Party>
  userSurveyDoc: Observable<Document>

  constructor(
    private router: Router,
    private apollo: Apollo,
    private trackingService: TrackingService,
    route: ActivatedRoute,
    @Inject(LocalStorage) private localStorage: Storage,
    @Inject(QUESTIONS_ABOUT_YOU_MODULE_CONFIG) config: QuestionsAboutYouModuleConfiguration,
    fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId,
    cms: CmsService
  ) {
    const forward = coerceBooleanProperty(route.snapshot.queryParams.fwd)
    if ((forward && !!localStorage.getItem('user-survey-shown')) || !config.showUserSurvey) {
      this.continue({ replaceUrl: true, saveForm: false })
      return
    }
    this.form = fb.group({
      field_1: '',
      field_2: [
        '',
        Validators.compose([
          Validators.min(1000),
          Validators.max(9999),
          Validators.pattern('[0-9]*')
        ])
      ],
      drop_1: '',
      drop_2: '',
      drop_3: '',
      drop_4: '',
      drop_5: '',
      drop_6: '',
      drop_7: '',
      drop_8: '',
      scale_1: '',
      other_party: '',
      other_party_name: ''
    })
    this.parties = this.getPartiesFromElections().pipe(
      tap((parties) => {
        parties.forEach((party) => {
          this.form.addControl(`party.${party.id}`, new FormControl(''))
        })
        this.restoreState(this.form, parties)
      })
    ) as any
    this.userSurveyDoc = cms
      .documents('user_survey')
      .pipe(map((docs) => docs[Object.keys(docs)[0]]))
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  continue(
    opts: { replaceUrl?: boolean; saveForm?: boolean } = { replaceUrl: false, saveForm: false }
  ) {
    localStorage.setItem('user-survey-shown', 'true')
    const { replaceUrl, saveForm } = opts
    if (saveForm) {
      this.saveState(this.form)
    }

    // tracking
    if (saveForm) {
      this.trackingService.trackEvent('Matching', 'saveQuestionsAboutYou')
    } else if (replaceUrl) {
      this.trackingService.trackEvent('Matching', 'autoSkipQuestionsAboutYou')
    } else {
      this.trackingService.trackEvent('Matching', 'skipQuestionsAboutYou')
    }

    this.router.navigate(['matching', 'results'], { replaceUrl })
  }

  setFormValue(formControlName: string, newValue: number) {
    const control = this.form.controls[formControlName]
    if (control.value === newValue) {
      control.reset('')
    } else if (control) {
      control.setValue(newValue)
    }
  }

  getFormValue(formControlName: string) {
    const control = this.form.controls[formControlName]
    if (control) {
      return control.value
    }
  }

  setPartyPreference(partyId: number, newValue: number) {
    const control = this.form.controls[`party.${partyId}`]
    if (control.value === newValue) {
      control.reset('')
    } else if (control) {
      control.setValue(newValue)
    }
  }

  getPartyPreference(partyId: string) {
    const control = this.form.controls[`party.${partyId}`]
    if (control) {
      return control.value
    }
  }

  private getPartiesFromElections() {
    return this.apollo
      .query<GetElectionPartiesQuery>({
        query: GetElectionParties
      })
      .pipe(
        map(({ data }) =>
          data.elections[0].parties.filter((party) =>
            party.values.some((value) => value.key === 'showInUserSurvey' && value.value === 'true')
          )
        )
      )
  }

  private restoreState(form: FormGroup, parties) {
    const state = this.localStorage.getItem(USER_SURVEY_KEY)
    if (!state) {
      return
    }

    try {
      const obj = JSON.parse(state)
      form.setValue(obj)
      // Mark everything as dirty
      form.markAsDirty()
      parties.forEach((p) => {
        if (obj[`party.${p.id}`]) {
          form.controls[`party.${p.id}`].markAsDirty()
        }
      })
    } catch (e) {}
  }

  private saveState(form) {
    this.localStorage.setItem(USER_SURVEY_KEY, JSON.stringify(form.value))
  }
}
