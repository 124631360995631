/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questionnaire-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./questionnare-navigation.component";
var styles_QuestionnaireNavigationComponent = [i0.styles];
var RenderType_QuestionnaireNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnaireNavigationComponent, data: {} });
export { RenderType_QuestionnaireNavigationComponent as RenderType_QuestionnaireNavigationComponent };
function View_QuestionnaireNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "svi-questionnaire-navigation-question"]], [[4, "width", "%"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigate(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { answered: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "tooltip d-none d-sm-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "svi-questionnaire-navigation-tooltip"]], [[4, "left", "px"]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ". ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "tooltip__arrow"]], [[4, "left", "px"]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "svi-questionnaire-navigation-question"; var currVal_2 = _ck(_v, 2, 0, (_co._hasAnswer && _co._hasAnswer[_v.context.index])); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (100 / _co.shortQuestions.length); _ck(_v, 0, 0, currVal_0); var currVal_3 = ((0 - 20) - ((90 / _co.shortQuestions.length) * _v.context.index)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_v.context.index + 1); var currVal_5 = _v.context.$implicit.textshort; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = (25 + ((90 / _co.shortQuestions.length) * _v.context.index)); _ck(_v, 6, 0, currVal_6); }); }
export function View_QuestionnaireNavigationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 4, "div", [["class", "svi-questionnaire-navigation-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "svi-questionnaire-navigation-question-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "svi-questionnaire-navigation-question active svi-questionnaire-navigation-current"]], [[4, "width", "%"], [4, "left", "%"]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireNavigationComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.shortQuestions; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (100 / _co.shortQuestions.length); var currVal_1 = ((100 / _co.shortQuestions.length) * _co._questionIndex); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_QuestionnaireNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-questionnaire-navigation", [], null, null, null, View_QuestionnaireNavigationComponent_0, RenderType_QuestionnaireNavigationComponent)), i1.ɵdid(1, 573440, null, 0, i3.QuestionnaireNavigationComponent, [i1.PLATFORM_ID], null, null)], null, null); }
var QuestionnaireNavigationComponentNgFactory = i1.ɵccf("svi-questionnaire-navigation", i3.QuestionnaireNavigationComponent, View_QuestionnaireNavigationComponent_Host_0, { questions: "questions", userAnswers: "userAnswers", questionIndex: "questionIndex" }, { navigate: "navigate" }, []);
export { QuestionnaireNavigationComponentNgFactory as QuestionnaireNavigationComponentNgFactory };
