import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  @Input() photoUrl: string
  @Input() rank: number
  @Input() title: string
  @Input() description: string
  @Input() hasMatching: boolean
  @Input() isDirectory: boolean
  @Input() matchingValue: number
  @Input() isCandidate: boolean
  @Input() hasProfile: boolean
  @Input() disabled: boolean
  @Input() isPlaceholder = false
}
