import { isPlatformBrowser } from '@angular/common';
import { ComponentFactoryResolver, ComponentRef, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { DemoModuleConfiguration } from './demo.module.configuiration';
import { DemoService } from './demo.service';
import { EndSessionComponent } from './end-session/end-session.component';
import { ResetDemoLayoverComponent } from './reset-demo-layover/reset-demo-layover.component';
var DemoDirective = /** @class */ (function () {
    function DemoDirective(demoService, config, platformId, router, viewContainerRef, resolver) {
        this.demoService = demoService;
        this.config = config;
        this.platformId = platformId;
        this.router = router;
        this.viewContainerRef = viewContainerRef;
        this.resolver = resolver;
        this.resetCompFactory = this.resolver.resolveComponentFactory(ResetDemoLayoverComponent);
        this.endSessionCompFactory = this.resolver.resolveComponentFactory(EndSessionComponent);
    }
    Object.defineProperty(DemoDirective.prototype, "sviDemo", {
        set: function (inactiveUrls) {
            var _this = this;
            if (isPlatformBrowser(this.platformId)) {
                if (this.config.enabled) {
                    this.routerSub = this.router.events.subscribe(function (val) {
                        if (val.url &&
                            inactiveUrls &&
                            inactiveUrls.indexOf(val.url) === -1 &&
                            !_this.demoService.isStarted()) {
                            _this.start();
                            _this.appendButton();
                        }
                    });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    DemoDirective.prototype.start = function () {
        var _this = this;
        if (this.config.enabled && isPlatformBrowser(this.platformId)) {
            if (this.userIsInactiveSub) {
                this.userIsInactiveSub.unsubscribe();
            }
            this.demoService.startCountdown();
            this.userIsInactiveSub = this.demoService.$userIsInactive.subscribe(function (count) {
                if (count === 0) {
                    _this.showLayover();
                }
            });
        }
    };
    DemoDirective.prototype.ngOnDestroy = function () {
        if (isPlatformBrowser(this.platformId)) {
            if (this.routerSub) {
                this.routerSub.unsubscribe();
            }
            if (this.endSessionSub) {
                this.endSessionSub.unsubscribe();
            }
            if (this.resetSub) {
                this.resetSub.unsubscribe();
            }
            if (this.closeSub) {
                this.closeSub.unsubscribe();
            }
            if (this.userIsInactiveSub) {
                this.userIsInactiveSub.unsubscribe();
            }
        }
    };
    DemoDirective.prototype.appendButton = function () {
        var _this = this;
        this.endSessionComp = this.viewContainerRef.createComponent(this.endSessionCompFactory);
        this.endSessionSub = this.endSessionComp.instance.endSession.subscribe(function () {
            _this.demoService.stopCountdown();
            if (_this.endSessionSub) {
                _this.endSessionSub.unsubscribe();
            }
            _this.removeButton();
            _this.showLayover();
        });
    };
    DemoDirective.prototype.removeButton = function () {
        this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.endSessionComp.hostView));
    };
    DemoDirective.prototype.showLayover = function () {
        var _this = this;
        var resetComp = this.viewContainerRef.createComponent(this.resetCompFactory);
        this.resetSub = resetComp.instance.reset.subscribe(function () {
            if (_this.resetSub) {
                _this.resetSub.unsubscribe();
            }
            _this.demoService.resetApp();
        });
        this.closeSub = resetComp.instance.close.subscribe(function () {
            if (_this.closeSub) {
                _this.closeSub.unsubscribe();
            }
            _this.viewContainerRef.clear();
            _this.start();
            _this.appendButton();
        });
    };
    DemoDirective.prototype.onActivity = function () {
        if (this.config.enabled && isPlatformBrowser(this.platformId)) {
            this.start();
        }
    };
    return DemoDirective;
}());
export { DemoDirective };
