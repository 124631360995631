<svi-navbar></svi-navbar>
<svi-page-title i18n="@@contact.title"> Contact </svi-page-title>
<svi-page-content class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <span
      class="validation-error"
      *ngIf="form.hasError('required', ['name']) && clicked"
      i18n="@@error.nameRequired"
      >Name required</span
    >
    <input
      type="text"
      required
      class="form-control"
      placeholder="Name"
      i18n-placeholder="@@contact.name"
      formControlName="name"
    />

    <span
      class="validation-error"
      *ngIf="form.hasError('required', ['email']) && clicked"
      i18n="@@error.emailRequired"
      >E-Mail required</span
    >
    <span
      class="validation-error"
      *ngIf="form.hasError('email', ['email']) && clicked"
      i18n="@@error.invalidEmail"
      >Invalid E-Mail</span
    >
    <input
      type="email"
      required
      email
      class="form-control"
      placeholder="E-Mail"
      i18n-placeholder="@@Email"
      formControlName="email"
    />

    <span
      class="validation-error"
      *ngIf="form.hasError('required', ['message']) && clicked"
      i18n="@@error.messageRequired"
      >Message required</span
    >
    <textarea
      required
      class="form-control"
      placeholder="Message"
      i18n-placeholder="@@contact.message"
      formControlName="message"
    ></textarea>

    <div class="button-wrapper">
      <button
        type="submit"
        [style.background-color]="getButtonColor()"
        class="button button-primary submit-button"
      >
        <ng-container *ngIf="sendState===undefined" i18n="@@contact.sendButton">Send</ng-container>
        <ng-container *ngIf="sendState===true" i18n="@@contact.emailSent">E-Mail sent</ng-container>
        <ng-container *ngIf="sendState===false" i18n="@@contact.emailSendError"
          >Error. Please try again later.</ng-container
        >
      </button>
    </div>
  </form>
  <!-- prismic content -->
  <div class="contact-address" [innerHTML]="(doc | async)?.html"></div>
</svi-page-content>
