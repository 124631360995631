<cdk-accordion>
  <svi-accordion-item
    title="More info about Smartspider"
    i18n-title="@@profile.methodologySmartspiderTitle"
  >
    <div class="info-panel">
      <p class="hint">
        <ng-container i18n="@@profile.methodologySmartspider"
          >This is a graphical visualisation based on the questions. Each axis represents 2-4
          questions. Some questions have been assigned to multiple axes and some questions have not
          been assigned to any axes. You can find out which questions are assigned with which axis
          on the
          <a
            class="methodology-link"
            href="https://demo.smartvote.org/en/wiki/methodology"
            i18n-href="@@profile.methodologySmartspiderUrl"
            >methodology page</a
          >.
        </ng-container>
      </p>
    </div>
  </svi-accordion-item>
</cdk-accordion>
