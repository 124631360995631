<div class="svi-questionnaire-navigation-container" #container>
  <div class="svi-questionnaire-navigation-question-container">
    <div
      class="svi-questionnaire-navigation-question active svi-questionnaire-navigation-current"
      [style.width.%]="100 / shortQuestions.length"
      [style.left.%]="(100 / shortQuestions.length) * _questionIndex"
    ></div>
    <div
      *ngFor="let question of shortQuestions; let i = index"
      class="svi-questionnaire-navigation-question"
      [ngClass]="{ answered: _hasAnswer && _hasAnswer[i] }"
      [style.width.%]="100 / shortQuestions.length"
      (click)="onNavigate(i)"
    >
      <div class="tooltip d-none d-sm-block">
        <div
          class="svi-questionnaire-navigation-tooltip"
          [style.left.px]="-20 - (90 / shortQuestions.length) * i"
        >
          {{ i + 1 }}.
          {{ question.textshort }}
          <div class="tooltip__arrow" [style.left.px]="25 + (90 / shortQuestions.length) * i"></div>
        </div>
      </div>
    </div>
  </div>
</div>
