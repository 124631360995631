<div *ngIf="facebookLink || websiteLink || twitterLink || youtubeLink">
  <span class="social-links-label" i18n="@@profile.socialLinks">Web profiles:</span>
  <div class="social-links">
    <a rel="external" target="_blank" *ngIf="websiteLink as url" [href]="getTrustedUrl(url)">
      <i class="fa fa-globe"></i>
    </a>
    <a rel="external" target="_blank" *ngIf="twitterLink as url" [href]="getTrustedUrl(url)">
      <i class="fab fa-twitter"></i>
    </a>
    <a rel="external" target="_blank" *ngIf="facebookLink as url" [href]="getTrustedUrl(url)">
      <i class="fab fa-facebook-square"></i>
    </a>
    <a rel="external" target="_blank" *ngIf="youtubeLink as url" [href]="getTrustedUrl(url)">
      <i class="fab fa-youtube"></i>
    </a>
  </div>
</div>
