import { Component } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { CmsService, Document, WikiDocumentLink } from '@core/cms.service'

@Component({
  selector: 'svi-wiki',
  templateUrl: './wiki.page.html',
  styleUrls: ['wiki.page.scss']
})
export class WikiPage {
  doc: Observable<Document>
  footerLinks: Observable<WikiDocumentLink>

  constructor(router: ActivatedRoute, cms: CmsService) {
    this.doc = router.paramMap.pipe(
      switchMap((params: ParamMap) =>
        cms.documents('wiki_doc').pipe(map((docs) => docs[params.get('docId')]))
      )
    )
    this.footerLinks = router.paramMap.pipe(
      switchMap((params: ParamMap) =>
        cms.getWikiDocumentLinks('wiki_doc').pipe(
          map((links: WikiDocumentLink[]) => {
            return links.find((link: WikiDocumentLink) => link.id === params.get('docId'))
          })
        )
      )
    )
  }
}
