import { Component, Inject, LOCALE_ID } from '@angular/core'
import { Location } from '@angular/common'
import { HttpParams } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import { LANG_DATA } from '../language-menu.component'

@Component({
  selector: 'svi-language-overlay',
  templateUrl: './language-overlay.component.html',
  styleUrls: ['./language-overlay.component.scss']
})
export class LanguageOverlayComponent {
  languages: any

  constructor(
    @Inject(LANG_DATA) public data: any,
    private location: Location,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    this.languages = data.languages
  }

  setLanguage(language: string) {
    let path = this.location.path(true)
    const idx = path.indexOf('?')
    // remove query part
    if (idx >= 0) {
      path = path.substr(0, idx)
    }
    const params = { ...this.route.snapshot.queryParams, locale: `${language}_CH` }
    const newUrl = `/${language}${path}?${new HttpParams({ fromObject: params }).toString()}`
    window.location.replace(newUrl)
  }

  isLanguageSelected(language: string) {
    return language === this.localeId
  }
}
