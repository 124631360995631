/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./language-menu.component";
import * as i3 from "@angular/cdk/overlay";
var styles_LanguageMenuComponent = [i0.styles];
var RenderType_LanguageMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageMenuComponent, data: {} });
export { RenderType_LanguageMenuComponent as RenderType_LanguageMenuComponent };
export function View_LanguageMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "languages-mobile d-block d-sm-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "language-menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeLanguageName(); _ck(_v, 2, 0, currVal_0); }); }
export function View_LanguageMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-language-menu", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LanguageMenuComponent_0, RenderType_LanguageMenuComponent)), i1.ɵdid(1, 49152, null, 0, i2.LanguageMenuComponent, [i1.ElementRef, i3.Overlay, i1.Injector, i1.LOCALE_ID], null, null)], null, null); }
var LanguageMenuComponentNgFactory = i1.ɵccf("svi-language-menu", i2.LanguageMenuComponent, View_LanguageMenuComponent_Host_0, { languages: "languages" }, {}, []);
export { LanguageMenuComponentNgFactory as LanguageMenuComponentNgFactory };
