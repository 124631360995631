import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core'
import { LocalStorage, SessionStorage } from '@core/tokens'
import { DemoModuleConfiguration, DEMO_MODULE_CONFIG } from './demo.module.configuiration'
import { BehaviorSubject, Observable, timer } from 'rxjs'
import { isPlatformBrowser } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class DemoService {
  $userIsInactive: Observable<number>
  private started: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(SessionStorage) public sessionStorage: Storage,
    @Optional() @Inject(LocalStorage) public localStorage: Storage,
    @Inject(DEMO_MODULE_CONFIG) private config: DemoModuleConfiguration
  ) {}

  resetApp() {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.clear()
      localStorage.clear()
      this.stopCountdown()
      window.location.replace('')
    }
    // reload app
    this.stopCountdown()
  }

  isStarted() {
    return this.started.getValue()
  }

  stopCountdown() {
    this.started.next(false)
  }

  startCountdown() {
    if (isPlatformBrowser(this.platformId)) {
      this.started.next(true)
      this.$userIsInactive = timer(this.config.maxInactivityInSeconds * 1000)
    }
  }
}
