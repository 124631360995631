import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'svi-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent {
  @Input() answerYes: string
  @Input() answerNo: string
  @Output() stateChanged: EventEmitter<string> = new EventEmitter()
  public state: string

  setButtonChoice(newState: string) {
    if (this.state === newState) {
      this.state = ''
    } else {
      this.state = newState
    }
    if (this.state === this.answerYes) {
      this.stateChanged.emit('true')
    } else if (this.state === this.answerNo) {
      this.stateChanged.emit('false')
    } else {
      this.stateChanged.emit('')
    }
  }
}
