import { Component, Input } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'svi-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent {
  @Input() websiteLink: string
  @Input() twitterLink: string
  @Input() facebookLink: string
  @Input() youtubeLink: string

  constructor(private _sanitizer: DomSanitizer) {}

  getTrustedUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url)
  }
}
