import { SVIVoterConfig } from './voter.configuration'

export const environment: SVIVoterConfig = {
  production: true,
  portierUrl: 'https://d9lodw0c6e.execute-api.eu-central-1.amazonaws.com/production/graphql',
  sharing: {
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot',
    twitterUsername: 'smartvoteCH'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<info@smartvote.org>',
  contactFormToAddress: 'info@digitalmonitor23.ch',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 15
  },
  cms: {
    repo: 'smartvote-international',
    version: 'Master',
    languageMapping: {
      de: 'de-ch',
      fr: 'fr-ch',
      it: 'it-ch'
    },
    tags: ['digimon-2023', 'voter']
  },
  profileModuleConfiguration: {
    hasSmartspider: true,
    showShareButton: true
  },
  matchingModuleConfiguration: { hasSmartmap: false, showDividingLine: false },
  questionnaireModuleConfiguration: { showWeight: true },
  questionsAboutYouModuleConfiguration: { showUserSurvey: false },
  demoModuleConfiguration: {
    layoverClosesAfterSeconds: 30,
    enabled: false,
    maxInactivityInSeconds: 180
  },
  showPrivacyLayover: false
}
