import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RichText } from 'prismic-dom';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
export var CmsSettingsService = new InjectionToken('cmsSettings');
/**
 * CmsService gets rendered content (html) over an API (Headless cms).
 * This implementation uses a managed/hosted cms service called
 * https://prismic.io. Go there to edit content.
 *
 * See function: `documents(..)`
 *
 * TODO: Move to @soom namespace when mature enough.
 */
var CmsService = /** @class */ (function () {
    function CmsService(http, sanitizer, localeId, settings) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.cache = {};
        this.apiBaseUrl = "https://" + settings.repo + ".cdn.prismic.io/api/v2";
        this.defaultVersion = settings.version;
        this.defaultLanguage = settings.languageMapping[localeId] || 'en-us';
        this.defaultTags = settings.tags;
    }
    /**
     * Get all documents from headless cms.
     *
     * Limitations:
     *  - Documents *must* have a field of type `UID`
     *  - Supported field types: `Title, Rich Text, Image, Embed` (Rich Text types)
     *
     * Notes:
     * - Documents are cached client side
     *
     * @param type The type of document (ex. wiki_doc, ...)
     * @param tags Filter docs by tags (optional)
     * @param lang The doc language (optional)
     * @param version The doc version (optional)
     */
    CmsService.prototype.documents = function (type, tags, lang, version) {
        var _this = this;
        if (tags === void 0) { tags = []; }
        if (lang === void 0) { lang = this.defaultLanguage; }
        if (version === void 0) { version = this.defaultVersion; }
        var concatenatedTags = tags.concat(this.defaultTags);
        var cacheKey = type + ":" + concatenatedTags.join('.') + ":" + lang + ":" + version;
        if (this.cache[cacheKey]) {
            return this.cache[cacheKey];
        }
        this.cache[cacheKey] = this.getBranchRef(version).pipe(switchMap(function (ref) { return _this.fetchDocuments(ref, type, concatenatedTags, lang, false); }), map(function (docs) { return _this.renderDocuments(docs); }), publishReplay(1), refCount());
        return this.cache[cacheKey];
    };
    CmsService.prototype.getWikiDocumentLinks = function (type, tags, lang, version) {
        var _this = this;
        if (tags === void 0) { tags = []; }
        if (lang === void 0) { lang = this.defaultLanguage; }
        if (version === void 0) { version = this.defaultVersion; }
        var concatenatedTags = tags.concat(this.defaultTags);
        return this.getBranchRef(version).pipe(switchMap(function (ref) { return _this.fetchDocuments(ref, type, concatenatedTags, lang, false); }), map(function (docs) {
            return docs
                .sort(function (docA, docB) { return docA.data.sortorder - docB.data.sortorder; })
                .map(function (doc) { return ({
                id: doc.uid,
                link: "/wiki/" + doc.uid,
                title: doc.data.title[0].text
            }); });
        }), publishReplay(1), refCount());
    };
    CmsService.prototype.documentsAsJson = function (type, tags, lang, version) {
        var _this = this;
        if (tags === void 0) { tags = []; }
        if (lang === void 0) { lang = this.defaultLanguage; }
        if (version === void 0) { version = this.defaultVersion; }
        return this.getBranchRef(version).pipe(switchMap(function (ref) { return _this.fetchDocuments(ref, type, tags, lang, true); }), publishReplay(1), refCount());
    };
    CmsService.prototype.fetchDocuments = function (ref, type, tags, lang, jsonResponse) {
        var headers = new HttpHeaders({
            Accept: 'application/json'
        });
        return this.http
            .get(this.apiBaseUrl + "/documents/search", {
            headers: jsonResponse ? headers : {},
            params: {
                ref: ref,
                lang: lang,
                q: [
                    "[[at(document.type, \"" + type + "\")]]",
                    "[[at(document.tags, [" + tags.map(function (t) { return "\"" + t + "\""; }).join(',') + "])]]"
                ]
            }
        })
            .pipe(map(function (res) { return (jsonResponse ? res.results : res.results); }));
    };
    CmsService.prototype.getBranchRef = function (version) {
        return this.http.get(this.apiBaseUrl).pipe(map(function (res) { return res.refs || []; }), map(function (refs) {
            var result = refs.find(function (ref) { return ref.label === version; });
            if (!result) {
                throw new Error('Could not find ref for version: ' + version);
            }
            return result.ref;
        }));
    };
    CmsService.prototype.renderDocuments = function (docResults) {
        var _this = this;
        var result = {};
        docResults
            .sort(function (a, b) {
            return a.data['sortorder'] && b.data['sortorder']
                ? a.data['sortorder'] - b.data['sortorder']
                : b.data['sortorder']
                    ? 1
                    : -1;
        })
            .map(function (d) { return _this.renderSlicedDocument(d); })
            .forEach(function (doc) {
            result[doc.id] = doc;
        });
        return result;
    };
    CmsService.prototype.renderSlicedDocument = function (doc) {
        var _this = this;
        if (!doc.uid) {
            throw new Error('Missing required field `UID` on document');
        }
        var html = '';
        var classNames = [];
        if (Array.isArray(doc.data.body)) {
            doc.data.body.forEach(function (slice) {
                switch (slice.slice_type) {
                    case 'content-block':
                        html += RichText.asHtml(slice.primary['content']);
                        break;
                    case 'columns':
                        html += _this.renderColumnSlice(slice);
                        break;
                    case 'partner':
                        html += _this.renderPartnerSlice(slice);
                        break;
                    default:
                        console.warn("CMS Service: no rendering function for slice type '" + slice.slice_type + "'");
                }
            });
        }
        if (doc.type === 'home') {
            classNames.push('home-tile');
            classNames.push("bg-" + doc.data['background']);
            if (doc.data['width'] === '100%') {
                classNames.push('full-width');
            }
            else if (doc.data['width'] === '50%') {
                classNames.push('half-width');
            }
        }
        return {
            id: doc.uid,
            html: this.sanitizer.bypassSecurityTrustHtml(html),
            className: classNames.join(' ')
        };
    };
    CmsService.prototype.renderColumnSlice = function (slice) {
        return "<div class=\"" + (slice.primary['responsive'] ? 'columns-responsive' : 'columns') + "\">" + slice.items
            .map(function (item) {
            return "<div class=\"column\" style=\"text-align: " + item.align + "\">" + RichText.asHtml(item.column) + "</div>";
        })
            .join('') + "</div>";
    };
    CmsService.prototype.renderPartnerSlice = function (slice) {
        return "<a class=\"partner-logo\" href=\"" + slice.primary['link']['url'] + "\" " + (slice.primary['link']['target'] ? 'target="_blank"' : '') + "><img src=\"" + slice.primary['image']['url'] + "\" /></a>";
    };
    CmsService.ngInjectableDef = i0.defineInjectable({ factory: function CmsService_Factory() { return new CmsService(i0.inject(i1.HttpClient), i0.inject(i2.DomSanitizer), i0.inject(i0.LOCALE_ID), i0.inject(CmsSettingsService)); }, token: CmsService, providedIn: "root" });
    return CmsService;
}());
export { CmsService };
