<div class="d-none d-sm-block navbar-placeholder"></div>
<svi-page-title>
  <span i18n="@@matching.sharedResult">Shared Results from</span>&nbsp;<span
    *ngIf="recommendation | async as recommendation"
    >{{recommendation.timestamp | date }}</span
  >
</svi-page-title>

<!-- Placeholder-->
<ng-template #hint>
  <svi-tab-group class="svi-matching-results" [(selectedIndex)]="tabIndex" [isPlaceholder]="true">
    <svi-tab label="Ranking" i18n-label="@@matching.ranking">
      <div #sharingElement="sviSharingElement" sviSharingElement>
        <svi-list-item
          *ngFor="let item of [1,2,3,4]; let i = index"
          [isPlaceholder]="true"
          [matchingValue]="0"
          [class.inactive]="true"
        >
        </svi-list-item>
      </div>
    </svi-tab>
    <svi-tab *ngIf="config.hasSmartmap" label="smartmap"> </svi-tab>
  </svi-tab-group>
</ng-template>

<svi-page-content>
  <div class="page-content-spacer" *ngIf="recommendation | async as recommendation;">
    <p style="margin-top: 0px">
      <ng-container i18n="@@district">District</ng-container>: {{resultSummary.district}}
    </p>
    <p
      *ngIf="recommendation.options.responderType === 'Candidate'"
      i18n="@@matching.showingCandidates"
    >
      Showing candidates
    </p>
    <p *ngIf="recommendation.options.responderType === 'Party'" i18n="@@matching.showingParties">
      Showing parties
    </p>
    <button class="button button-primary icon-right" (click)="navigateToHome()">
      <ng-container i18n="@@matching.getYourOwnMatching">Get your own matching</ng-container>
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</svi-page-content>

<ng-container *ngIf="recommendation | async as recommendation; else hint">
  <svi-tab-group
    class="svi-matching-results"
    (selectedTabChanged)="onTabChanged($event)"
    [(selectedIndex)]="tabIndex"
  >
    <svi-tab label="Ranking" i18n-label="@@matching.ranking">
      <!-- Sharing Info, set meta
        tags only if this tab is active -->
      <ng-container
        #sharingInfo="sviSharingInfo"
        sviSharingInfo
        [setMetaTags]="tabIndex === 0"
        title="smartvote internationl demo"
        i18n-title="@@matching.sharingTitle"
        description="Here is my voting advice for the smartvote international demo"
        i18n-description="@@matching.sharingDescription"
        [imagePath]="'/assets/images/svint_demo_static_sharing_image_' + localeId + '.jpg'"
        [sharePath]="'/' + localeId + '/share/results?tab=0&rid=' + recommendation.id"
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo}"
      ></ng-container>
      <div #sharingElement="sviSharingElement" sviSharingElement>
        <svi-list-item
          *ngFor="let matching of recommendation.matchings; let i = index"
          [class.lastseat]="config.showDividingLine && recommendation.options.responderType === 'Candidate' && i === nofSeats - 1"
          [class.inactive]="config.showDividingLine && recommendation.options.responderType === 'Candidate' && i > nofSeats - 1"
          [photoUrl]="matching.responder ? matching.responder.photoUrl : null"
          [rank]="i + 1"
          [title]="matching.title"
          [description]="matching.description"
          [isDirectory]="false"
          [hasMatching]="(matching.matchValue && matching.matchValue !== -9) || matching.matchValue === 0"
          [matchingValue]="matching.matchValue"
          [isCandidate]="recommendation.options.responderType === 'Candidate'"
          (click)="onMatchingSelected(matching)"
        >
        </svi-list-item>
      </div>
    </svi-tab>
    <svi-tab *ngIf="config.hasSmartmap" label="smartmap">
      <!-- Sharing Info, set meta
            tags only if this tab is active -->
      <div class="checkbox-wrapper">
        <svi-custom-checkbox
          [checked]="showVoterPosition"
          (valueChanged)="showVoterPosition = $event"
          childId="my-smartmap-position"
        >
          <span i18n="@@matching.mySmartmapPosition">Meine smartmap Position</span>
        </svi-custom-checkbox>
      </div>
      <ng-container
        #sharingInfo2="sviSharingInfo"
        sviSharingInfo
        [setMetaTags]="tabIndex === 1"
        title="smartvote international demo"
        i18n-title="@@smartmap.sharingTitle"
        description="Here is my smartmap for the smartvote international demo"
        i18n-description="@@smartmap.sharingDescription"
        [imageFromElement]="sharingElement2"
        [sharePath]="'/' + localeId + '/share/results?tab=1&rid=' + recommendation.id"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo2}"
      ></ng-container>

      <div class="smartmap">
        <ng-container *ngIf="elections | async as elections;">
          <sv-smartmap
            #sharingElement2="sviSharingElement"
            sviSharingElement
            [screenshotHeight]="560"
            [myPosition]="showVoterPosition ? recommendation.voter.smartmapPosition : null"
            [positions]="recommendation.positions"
            [labelLeft]="elections[0].dimensions[0].pole1"
            [labelRight]="elections[0].dimensions[0].pole2"
            [labelTop]="elections[0].dimensions[1].pole2"
            [labelBottom]="elections[0].dimensions[1].pole1"
            (onSelect)="onSmartmapItemSelect($event)"
          ></sv-smartmap>
        </ng-container>
        <ul class="smartmap-legend">
          <li *ngFor="let item of recommendation.smartmapLegendItems">
            <svi-chart-legend-item
              [label]="item.label"
              [color]="item.color"
            ></svi-chart-legend-item>
          </li>
        </ul>
        <svi-smartmap-methodology></svi-smartmap-methodology>
      </div>
    </svi-tab>
  </svi-tab-group>
  <div class="svi-matching-result-summary">
    <div class="item">
      <ng-container i18n="@@district">District</ng-container>: {{resultSummary.district}}
    </div>
    <div class="item">
      <ng-container i18n="@@matching.numberOfSeats">No. of seats</ng-container>:
      {{resultSummary.nofSeats}}
    </div>
    <div class="item">
      <ng-container i18n="@@matching.numberOfCandidates">No. of candidates</ng-container>:
      {{resultSummary.nofCandidates}}
    </div>
    <!-- <div class="item">
      <ng-container i18n>No. of participating candidates</ng-container>: {{resultSummary.nofParticipatingCandidates}}
    </div> -->
  </div>
</ng-container>

<ng-template #translations>
  <span id="no-information" i18n="@@matching.noInformation">No information</span>
  <span id="my-position" i18n="@@matching.myPosition">My position</span>
</ng-template>
