import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { combineLatest } from 'rxjs';
import { VoterIdService } from '../core/voter-id.service';
import { isPlatformBrowser } from '@angular/common';
var _a = require('graphql-tag/loader!./details.page.graphql'), GetPartyDetails = _a.GetPartyDetails, GetPartyDetailsWithVoter = _a.GetPartyDetailsWithVoter;
var PartyDetailsSharePage = /** @class */ (function () {
    function PartyDetailsSharePage(route, router, voterIdService, apollo, platformId) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.apollo = apollo;
        this.platformId = platformId;
        this.tabIndex = 0;
        this.data = combineLatest([route.params, route.queryParams]).pipe(switchMap(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.tabIndex = parseInt(queryParams['tab'], 10) || 0;
            if (queryParams.rid) {
                return _this._getDataWithVoter(params.id, queryParams.rid);
            }
            else {
                return _this._getData(params.id);
            }
        }), map(function (_a) {
            var data = _a.data;
            var _b = data, party = _b.party, questionnaire = _b.questionnaire, recommendation = _b.recommendation;
            // Deep copy hack for issue: https://github.com/apollographql/apollo-angular/issues/329
            if (recommendation) {
                return {
                    party: party,
                    questions: questionnaire.questions,
                    voter: tslib_1.__assign({}, recommendation.voter, { isMe: voterIdService.getVoterId() === recommendation.voter.id })
                };
            }
            else {
                return {
                    party: party,
                    questions: questionnaire.questions
                };
            }
        }));
    }
    PartyDetailsSharePage.prototype.ngAfterViewInit = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }
    };
    PartyDetailsSharePage.prototype.onTabChanged = function (index) {
        this._updateQueryParams({ tab: index });
    };
    PartyDetailsSharePage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    PartyDetailsSharePage.prototype._getDataWithVoter = function (partyId, recommendationId, electionId) {
        if (electionId === void 0) { electionId = '1'; }
        return this.apollo.query({
            query: GetPartyDetailsWithVoter,
            variables: { partyId: partyId, electionId: electionId, recommendationId: recommendationId }
        });
    };
    PartyDetailsSharePage.prototype._getData = function (partyId, electionId) {
        if (electionId === void 0) { electionId = '1'; }
        return this.apollo.query({
            query: GetPartyDetails,
            variables: { partyId: partyId, electionId: electionId }
        });
    };
    return PartyDetailsSharePage;
}());
export { PartyDetailsSharePage };
