<div class="svi-questionnaire-answer">
  <div class="answer-container">
    <div style="width: 100px" class="d-none d-sm-block"></div>
    <div
      class="answer-options standard"
      *ngIf="isStandard5Question"
      [ngClass]="{ with5options: isStandard5Question }"
    >
      <button
        (click)="answerChange(0)"
        [ngClass]="{ active: userAnswer.value === 0 }"
        i18n="@@questionnaire.answerMuchLess"
      >
        Much<br />less
      </button>
      <button
        (click)="answerChange(25)"
        [ngClass]="{ active: userAnswer.value === 25 }"
        i18n="@@questionnaire.answerLess"
      >
        Less
      </button>
      <button
        *ngIf="isStandard5Question"
        (click)="answerChange(50)"
        [ngClass]="{ active: userAnswer.value === 50 }"
        i18n="@@questionnaire.answerNeutral"
      >
        Same
      </button>
      <button
        (click)="answerChange(75)"
        [ngClass]="{ active: userAnswer.value === 75 }"
        i18n="@@questionnaire.answerMore"
      >
        More
      </button>
      <button
        (click)="answerChange(100)"
        [ngClass]="{ active: userAnswer.value === 100 }"
        i18n="@@questionnaire.answerMuchMore"
      >
        Much<br />more
      </button>
    </div>
    <div class="answer-options" *ngIf="!isStandard5Question">
      <button
        (click)="answerChange(100)"
        [ngClass]="{ active: userAnswer.value === 100 }"
        i18n="@@questionnaire.answerYes"
      >
        Yes
      </button>
      <button
        (click)="answerChange(75)"
        [ngClass]="{ active: userAnswer.value === 75 }"
        i18n="@@questionnaire.answerRatherYes"
      >
        Rather<br />yes
      </button>
      <button
        (click)="answerChange(25)"
        [ngClass]="{ active: userAnswer.value === 25 }"
        i18n="@@questionnaire.answerRatherNo"
      >
        Rather<br />no
      </button>
      <button
        (click)="answerChange(0)"
        [ngClass]="{ active: userAnswer.value === 0 }"
        i18n="@@questionnaire.answerNo"
      >
        No
      </button>
    </div>
    <div [class.hidden]="responderType === 'Candidate'" class="no-answer d-none d-sm-block">
      <button
        (click)="answerChange(-9)"
        [ngClass]="{ active: userAnswer.value === -9 }"
        i18n="@@questionnaire.noAnswer"
      >
        No answer
      </button>
    </div>
  </div>
  <div *ngIf="showWeightButtons" class="weight-container-outer">
    <div class="weight-container-inner">
      <div class="weight-options">
        <div class="title" i18n="@@questionnaire.weightAnswer">Weight answer!</div>
        <button (click)="weightChange(0.5)" [ngClass]="{ active: userAnswer.weight === 0.5 }">
          <i class="fa fa-minus"></i>
        </button>
        <button (click)="weightChange(1)" [ngClass]="{ active: userAnswer.weight === 1 }">
          <i class="fa fa-equals"></i>
        </button>
        <button (click)="weightChange(2)" [ngClass]="{ active: userAnswer.weight === 2 }">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <div [class.hidden]="responderType === 'Candidate'" class="no-answer d-block d-sm-none">
        <button
          (click)="answerChange(-9)"
          [ngClass]="{ active: userAnswer.value === -9 }"
          i18n="@@questionnaire.noAnswer"
        >
          No answer
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="!showWeightButtons"
    [class.hidden]="responderType === 'Candidate'"
    class="no-answer center d-block d-sm-none"
  >
    <button
      (click)="answerChange(-9)"
      [ngClass]="{ active: userAnswer.value === -9 }"
      i18n="@@questionnaire.noAnswer"
    >
      No answer
    </button>
  </div>
</div>
