import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, InjectionToken, LOCALE_ID } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { RichText } from 'prismic-dom'
import { Observable } from 'rxjs'
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators'

export const CmsSettingsService = new InjectionToken<CmsSettings>('cmsSettings')

export interface CmsSettings {
  repo: string
  version: string
  languageMapping: Object
  tags: string[]
}

export interface Document {
  id: string
  html: string
  className?: string
}

export interface WikiDocumentLink {
  id: string
  link: string
  title: string
}

interface DocumentResult {
  uid: string
  tags: string[]
  data: any
  type: 'home' | 'wiki_doc' | 'partner'
}

interface Slice {
  items: Object[]
  primary: Object
  slice_label: string
  slice_type: string
}

interface ColumnSliceItem {
  align: string
  column: any[]
}

/**
 * CmsService gets rendered content (html) over an API (Headless cms).
 * This implementation uses a managed/hosted cms service called
 * https://prismic.io. Go there to edit content.
 *
 * See function: `documents(..)`
 *
 * TODO: Move to @soom namespace when mature enough.
 */

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private apiBaseUrl: string
  private defaultVersion: string
  private defaultLanguage: string
  private cache: { [key: string]: Observable<{ [id: string]: Document }> } = {}
  private defaultTags: string[]

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) localeId: string,
    @Inject(CmsSettingsService) settings: CmsSettings
  ) {
    this.apiBaseUrl = `https://${settings.repo}.cdn.prismic.io/api/v2`
    this.defaultVersion = settings.version
    this.defaultLanguage = settings.languageMapping[localeId] || 'en-us'
    this.defaultTags = settings.tags
  }

  /**
   * Get all documents from headless cms.
   *
   * Limitations:
   *  - Documents *must* have a field of type `UID`
   *  - Supported field types: `Title, Rich Text, Image, Embed` (Rich Text types)
   *
   * Notes:
   * - Documents are cached client side
   *
   * @param type The type of document (ex. wiki_doc, ...)
   * @param tags Filter docs by tags (optional)
   * @param lang The doc language (optional)
   * @param version The doc version (optional)
   */

  public documents(
    type: string,
    tags: string[] = [],
    lang: string = this.defaultLanguage,
    version: string = this.defaultVersion
  ): Observable<{ [id: string]: Document }> {
    const concatenatedTags = tags.concat(this.defaultTags)
    const cacheKey = `${type}:${concatenatedTags.join('.')}:${lang}:${version}`
    if (this.cache[cacheKey]) {
      return this.cache[cacheKey]
    }
    this.cache[cacheKey] = this.getBranchRef(version).pipe(
      switchMap((ref) => this.fetchDocuments(ref, type, concatenatedTags, lang, false)),
      map((docs) => this.renderDocuments(docs)),
      publishReplay(1),
      refCount()
    )
    return this.cache[cacheKey]
  }

  public getWikiDocumentLinks(
    type: string,
    tags: string[] = [],
    lang: string = this.defaultLanguage,
    version: string = this.defaultVersion
  ): Observable<WikiDocumentLink[]> {
    const concatenatedTags = tags.concat(this.defaultTags)
    return this.getBranchRef(version).pipe(
      switchMap((ref) => this.fetchDocuments(ref, type, concatenatedTags, lang, false)),
      map((docs) => {
        return docs
          .sort((docA, docB) => docA.data.sortorder - docB.data.sortorder)
          .map((doc) => ({
            id: doc.uid,
            link: `/wiki/${doc.uid}`,
            title: doc.data.title[0].text
          }))
      }),
      publishReplay(1),
      refCount()
    )
  }

  public documentsAsJson(
    type: string,
    tags: string[] = [],
    lang: string = this.defaultLanguage,
    version: string = this.defaultVersion
  ) {
    return this.getBranchRef(version).pipe(
      switchMap((ref) => this.fetchDocuments(ref, type, tags, lang, true)),
      publishReplay(1),
      refCount()
    )
  }

  private fetchDocuments(
    ref: string,
    type: string,
    tags: string[],
    lang: string,
    jsonResponse: boolean
  ): Observable<DocumentResult[]> | Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/json'
    })
    return this.http
      .get(`${this.apiBaseUrl}/documents/search`, {
        headers: jsonResponse ? headers : {},
        params: {
          ref,
          lang,
          q: [
            `[[at(document.type, "${type}")]]`,
            `[[at(document.tags, [${tags.map((t) => `"${t}"`).join(',')}])]]`
          ]
        }
      })
      .pipe(
        map((res: any) => (jsonResponse ? (res.results as any) : (res.results as DocumentResult[])))
      )
  }

  private getBranchRef(version: string): Observable<string> {
    return this.http.get(this.apiBaseUrl).pipe(
      map((res: any) => res.refs || []),
      map((refs: any[]) => {
        const result = refs.find((ref) => ref.label === version)
        if (!result) {
          throw new Error('Could not find ref for version: ' + version)
        }
        return result.ref
      })
    )
  }

  private renderDocuments(docResults: DocumentResult[]) {
    const result = {}
    docResults
      .sort((a: DocumentResult, b: DocumentResult) =>
        a.data['sortorder'] && b.data['sortorder']
          ? a.data['sortorder'] - b.data['sortorder']
          : b.data['sortorder']
          ? 1
          : -1
      )
      .map((d) => this.renderSlicedDocument(d))
      .forEach((doc) => {
        result[doc.id] = doc
      })
    return result
  }

  private renderSlicedDocument(doc: DocumentResult) {
    if (!doc.uid) {
      throw new Error('Missing required field `UID` on document')
    }
    let html = ''
    const classNames = []
    if (Array.isArray(doc.data.body)) {
      doc.data.body.forEach((slice: Slice) => {
        switch (slice.slice_type) {
          case 'content-block':
            html += RichText.asHtml(slice.primary['content'])
            break
          case 'columns':
            html += this.renderColumnSlice(slice)
            break
          case 'partner':
            html += this.renderPartnerSlice(slice)
            break
          default:
            console.warn(`CMS Service: no rendering function for slice type '${slice.slice_type}'`)
        }
      })
    }
    if (doc.type === 'home') {
      classNames.push('home-tile')
      classNames.push(`bg-${doc.data['background']}`)
      if (doc.data['width'] === '100%') {
        classNames.push('full-width')
      } else if (doc.data['width'] === '50%') {
        classNames.push('half-width')
      }
    }
    return {
      id: doc.uid,
      html: this.sanitizer.bypassSecurityTrustHtml(html),
      className: classNames.join(' ')
    }
  }

  private renderColumnSlice(slice: Slice): string {
    return `<div class="${slice.primary['responsive'] ? 'columns-responsive' : 'columns'}">${(
      slice.items as ColumnSliceItem[]
    )
      .map(
        (item) =>
          `<div class="column" style="text-align: ${item.align}">${RichText.asHtml(
            item.column
          )}</div>`
      )
      .join('')}</div>`
  }

  private renderPartnerSlice(slice: Slice): string {
    return `<a class="partner-logo" href="${slice.primary['link']['url']}" ${
      slice.primary['link']['target'] ? 'target="_blank"' : ''
    }><img src="${slice.primary['image']['url']}" /></a>`
  }
}
