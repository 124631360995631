import * as tslib_1 from "tslib";
import { TemplateRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { AnswerService } from '../core/answer.service';
import { VoterIdService } from '../core/voter-id.service';
import { CmsService } from '../core/cms.service';
import { FilterGroupState, stateIsEqual, stateIsValid } from './filter-group/filter-group.component';
import { USER_SURVEY_KEY } from '../questions-about-you/questions-about-you.page';
import { TrackingService } from '../core/tracking.service';
import { IframeSettings } from '@core/iframe';
import { QUESTIONNAIRE_START, QUESTIONNAIRE_END, RECOMMENDATION_CREATED_AT } from 'app/questionnaire/questionnaire.page';
var _a = require('graphql-tag/loader!./matching.page.graphql'), CreateRecommendation = _a.CreateRecommendation, GetRecommendation = _a.GetRecommendation, GetElections = _a.GetElections;
var MatchingPage = /** @class */ (function () {
    function MatchingPage(apollo, router, route, answerService, voterIdService, trackingService, _localStorage, localeId, config, iframeSettings, cms) {
        var _this = this;
        this.apollo = apollo;
        this.router = router;
        this.route = route;
        this.answerService = answerService;
        this.voterIdService = voterIdService;
        this.trackingService = trackingService;
        this._localStorage = _localStorage;
        this.localeId = localeId;
        this.config = config;
        this.iframeSettings = iframeSettings;
        this.tabIndex = 0;
        this.showVoterPosition = true;
        this.filterGroupStateChanges = new BehaviorSubject(new FilterGroupState());
        this.filterGroupStateFromRecommendation = {
            district: '',
            districtGroup: '',
            election: '',
            responderType: 'Candidate'
        };
        this.resultSummary = {
            district: '',
            nofSeats: '',
            nofCandidates: ''
            // nofParticipatingCandidates: '' // TODO: Figure out how to get this number
        };
        this.loadingRecommendation = true;
        this.loadingPrismic = false;
        this._filterGroupState = new FilterGroupState();
        this.elections = this._getElections(); // TODO: Fix types
        this.recommendation = combineLatest([
            this.route.queryParams,
            this.filterGroupStateChanges,
            this.elections
        ]).pipe(switchMap(function (_a) {
            var params = _a[0], filterGroupState = _a[1], elections = _a[2];
            _this._elections = elections;
            _this.tabIndex = parseInt(params['tab'], 10) || 0;
            var recommendationId = params['rid'];
            _this.loadingRecommendation = true;
            if (_this._doCreateRecommendation(filterGroupState)) {
                return _this.createRecommendation(filterGroupState);
            }
            else if (_this._doGetRecommendation(recommendationId)) {
                return _this._getRecommendation(recommendationId, 0, -1);
            }
            else if (stateIsValid(filterGroupState)) {
                return of(_this._recommendation);
            }
            else {
                _this._updateQueryParams({ rid: null });
                return of(null);
            }
        }), tap(function (recommendation) {
            // TODO(JLO, 03.09.18): redirect to share view if it's not me
            _this.loadingRecommendation = false;
            if (!recommendation) {
                return;
            }
            recommendation.positions = getSmartmapPositions(recommendation);
            recommendation.smartmapLegendItems = getSmartmapLegendItems(recommendation);
            recommendation.matchings = recommendation.matchings.map(function (matching) { return (tslib_1.__assign({}, matching, _this._getListItemLabels(matching))); });
            if (!_this._recommendation || _this._recommendation.id !== recommendation.id) {
                // Keep last state before emiting to avoid infite loop
                var districts = _this._elections.filter(function (e) { return e.id === recommendation.options.electionId; })[0].districts;
                var districtGroupId = districts
                    .filter(function (d) { return d.id === recommendation.options.districtId; })
                    .map(function (d) { return d.groupId; })[0];
                // Input districtGroupId manually, since it is not in recommendation.options
                _this._filterGroupState = _this._getFilterGroupState(tslib_1.__assign({}, recommendation.options, { districtGroupId: districtGroupId }));
                _this._recommendation = recommendation;
                _this._updateQueryParams({ rid: recommendation.id });
                _this._updateResultSummary(_this._elections, _this._filterGroupState, recommendation);
                _this._updateNofSeats(_this._elections, _this._filterGroupState);
                _this.filterGroupStateFromRecommendation = tslib_1.__assign({}, _this._filterGroupState);
            }
            _this._localStorage.setItem('recommendationId', recommendation.id);
        }));
        this.loadingPrismic = true;
        this.matchingPageDoc = cms.documents('matching_page').pipe(map(function (docs) {
            _this.loadingPrismic = false;
            return docs[Object.keys(docs)[0]];
        }));
    }
    MatchingPage.prototype.ngAfterViewInit = function () {
        this.translations = this.translationTemplate
            .createEmbeddedView({})
            .rootNodes.reduce(function (prev, curr) {
            var _a;
            return (tslib_1.__assign({}, prev, (_a = {}, _a[curr.id] = curr.textContent, _a)));
        }, {});
    };
    MatchingPage.prototype.trackSharingTrigger = function () {
        this.trackingService.trackEvent('Matching', 'openSharingDialog');
    };
    MatchingPage.prototype.onFilterGroupChanged = function (state) {
        this.filterGroupStateChanges.next(state);
    };
    MatchingPage.prototype.onTabChanged = function (index) {
        this._updateQueryParams({ tab: index });
    };
    MatchingPage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    MatchingPage.prototype._updateResultSummary = function (elections, filterGroupState, recommendation) {
        if (!(elections && filterGroupState && recommendation)) {
            return;
        }
        var election = elections.find(function (e) { return e.id === filterGroupState.election; });
        if (!election) {
            return;
        }
        var district = election.districts.find(function (d) { return d.id === filterGroupState.district; });
        this.resultSummary = {
            district: district.name,
            nofSeats: "" + district.seats,
            nofCandidates: "" + recommendation.matchings.length
        };
    };
    MatchingPage.prototype._updateNofSeats = function (elections, filterGroupState) {
        if (!elections) {
            return;
        }
        var election = elections.find(function (e) { return e.id === filterGroupState.election; });
        if (!election) {
            return;
        }
        var district = election.districts.find(function (d) { return d.id === filterGroupState.district; });
        this.nofSeats = district.seats;
    };
    MatchingPage.prototype._getListItemLabels = function (matching) {
        var title = '';
        var description = '';
        if (matching.responderType === 'Candidate') {
            var candidate = matching.responder;
            if (candidate) {
                title = candidate.firstname + " " + candidate.lastname;
                description = candidate.party ? candidate.party.abbreviation : '';
            }
            else {
                title = this.getTranslatedMessage('no-information');
            }
        }
        else if (matching.responderType === 'Party') {
            var party = matching.responder;
            if (party) {
                title = party.name;
            }
            else {
                title = this.getTranslatedMessage('no-information');
            }
        }
        return { title: title, description: description, matchingValue: matching.matchValue };
    };
    MatchingPage.prototype._getFilterGroupState = function (options) {
        return {
            election: options.electionId,
            district: options.districtId,
            districtGroup: options.districtGroupId,
            responderType: options.responderType
        };
    };
    MatchingPage.prototype._getElections = function () {
        return this.apollo
            .query({
            query: GetElections
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.elections;
        }));
    };
    MatchingPage.prototype._getRecommendation = function (recommendationId, offset, limit) {
        return this.apollo
            .query({
            query: GetRecommendation,
            variables: {
                recommendationId: recommendationId,
                offset: offset,
                limit: limit
            }
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (tslib_1.__assign({}, data.recommendation));
        }));
    };
    MatchingPage.prototype._doCreateRecommendation = function (filterGroupState) {
        if (stateIsEqual(filterGroupState, this._filterGroupState) || !stateIsValid(filterGroupState)) {
            return false;
        }
        else {
            return true;
        }
    };
    MatchingPage.prototype._doGetRecommendation = function (rid) {
        return rid && !this._recommendation;
    };
    MatchingPage.prototype.createRecommendation = function (filterGroupState) {
        this._localStorage.setItem(RECOMMENDATION_CREATED_AT, new Date().getTime().toString());
        var answers = this.answerService.getAnswers();
        var surveyData = this._localStorage.getItem(USER_SURVEY_KEY) || '';
        var start = this._localStorage.getItem(QUESTIONNAIRE_START) || '';
        var end = this._localStorage.getItem(QUESTIONNAIRE_END) || '';
        var options = {
            responderType: filterGroupState.responderType,
            electionId: filterGroupState.election,
            districtId: filterGroupState.district,
            voterId: this.voterIdService.getVoterId(),
            answers: answers,
            surveyData: surveyData,
            start: start,
            end: end
        };
        if (this.iframeSettings.enabled) {
            options.inlineFrameId = this.iframeSettings.id;
        }
        return this.apollo
            .mutate({
            mutation: CreateRecommendation,
            variables: {
                options: options
            }
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (tslib_1.__assign({}, data.createRecommendation));
        }));
    };
    MatchingPage.prototype.goToUserSurvey = function () {
        this.router.navigate(['matching', 'questions-about-you'], { queryParams: { fwd: true } });
    };
    MatchingPage.prototype.onMatchingSelected = function (matching) {
        if (matching.responder) {
            if (matching.responderType === 'Candidate') {
                this.router.navigate([
                    'matching',
                    'profile',
                    'candidate',
                    matching.responder.id,
                    matching.responder.district.electionId
                ], { queryParamsHandling: 'preserve' });
            }
            else if (matching.responderType === 'Party') {
                this.router.navigate(['matching', 'profile', 'party', matching.responder.id], {
                    queryParamsHandling: 'preserve'
                });
            }
        }
    };
    MatchingPage.prototype.onSmartmapItemSelect = function (itemId) {
        var selectedMatching = this._recommendation.matchings.filter(function (matching) { return matching.responderId === itemId; });
        if (selectedMatching.length === 1) {
            this.onMatchingSelected(selectedMatching[0]);
        }
    };
    MatchingPage.prototype.getTranslatedMessage = function (msg) {
        return this.translations[msg];
    };
    return MatchingPage;
}());
export { MatchingPage };
export function getValue(responder, key) {
    if (!responder) {
        return null;
    }
    var result = responder.values.find(function (entry) { return entry.key === key; });
    return result ? result.value : null;
}
export function getSmartmapLegendItems(recommendation) {
    var partyColors = {};
    recommendation.matchings
        .filter(function (matching) { return !!matching.responder && matching.responder.nofAnswers > 0; })
        .forEach(function (matching) {
        if (matching.responderType === 'Candidate') {
            var candidate = matching.responder;
            if (candidate.party) {
                var label = candidate.party.abbreviation || candidate.party.name;
                partyColors[label] = '#' + getValue(candidate.party, 'color');
            }
        }
        else if (matching.responderType === 'Party') {
            var party = matching.responder;
            var label = party.abbreviation || party.name;
            partyColors[label] = '#' + getValue(party, 'color');
        }
    });
    return Object.keys(partyColors).map(function (k) {
        return { label: k, color: partyColors[k] };
    });
}
export function getSmartmapPositions(recommendation) {
    return recommendation.matchings
        .filter(function (matching) { return !!matching.responder && matching.responder.nofAnswers > 0; })
        .map(function (matching) {
        if (matching.responderType === 'Candidate') {
            var candidate = matching.responder;
            var candidateLabel = candidate.party
                ? candidate.firstname + " " + candidate.lastname + " (" + candidate.party.name + ")"
                : candidate.firstname + " " + candidate.lastname;
            return tslib_1.__assign({ id: candidate.id, color: '#' + getValue(candidate.party, 'color'), label: candidateLabel }, candidate.smartmapPosition);
        }
        else if (matching.responderType === 'Party') {
            var party = matching.responder;
            return tslib_1.__assign({ id: party.id, color: '#' + getValue(party, 'color'), label: party.name }, party.smartmapPosition);
        }
    });
}
