import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'svi-end-session',
  templateUrl: './end-session.component.html',
  styleUrls: ['./end-session.component.scss']
})
export class EndSessionComponent {
  @Output() endSession: EventEmitter<void> = new EventEmitter<void>()
}
