<ul class="nav-list-mobile">
  <a
    class="nav-item-mobile"
    routerLink="/home"
    (click)="close()"
    routerLinkActive="active"
    i18n="@@menu.home.title"
    >Home</a
  >
  <a
    class="nav-item-mobile"
    routerLink="/matching"
    (click)="goToMatching()"
    routerLinkActive="active"
    i18n="@@menu.matching.title"
    >Matching</a
  >
  <a
    class="nav-item-mobile"
    routerLink="/profiles"
    (click)="close()"
    routerLinkActive="active"
    i18n="@@menu.profiles.title"
    >Profiles</a
  >
</ul>
