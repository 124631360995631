/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switch-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./switch-button.component";
var styles_SwitchButtonComponent = [i0.styles];
var RenderType_SwitchButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitchButtonComponent, data: {} });
export { RenderType_SwitchButtonComponent as RenderType_SwitchButtonComponent };
export function View_SwitchButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "svi-switch-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setButtonChoice(_co.answerYes) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setButtonChoice(_co.answerNo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { active: 0 }), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.state === _co.answerYes)); _ck(_v, 2, 0, currVal_0); var currVal_2 = _ck(_v, 7, 0, (_co.state === _co.answerNo)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.answerYes; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.answerNo; _ck(_v, 8, 0, currVal_3); }); }
export function View_SwitchButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-switch-button", [], null, null, null, View_SwitchButtonComponent_0, RenderType_SwitchButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.SwitchButtonComponent, [], null, null)], null, null); }
var SwitchButtonComponentNgFactory = i1.ɵccf("svi-switch-button", i3.SwitchButtonComponent, View_SwitchButtonComponent_Host_0, { answerYes: "answerYes", answerNo: "answerNo" }, { stateChanged: "stateChanged" }, []);
export { SwitchButtonComponentNgFactory as SwitchButtonComponentNgFactory };
